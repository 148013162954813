// Fetch User Wallets
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const FETCH_USER_PROFILE_COMPLETION = "FETCH_USER_PROFILE_COMPLETION";
export const FETCH_USER_PROFILE_COMPLETION_SUCCESS =
  "FETCH_USER_PROFILE_COMPLETION_SUCCESS";
export const FETCH_USER_PROFILE_COMPLETION_FAILURE =
  "FETCH_USER_PROFILE_COMPLETION_FAILURE";

export const USER_CONTACT = "USER_CONTACT";
export const USER_CONTACT_SUCCESS = "USER_CONTACT_SUCCESS";
export const USER_CONTACT_ERROR = "USER_CONTACT_ERROR";

export const VERIFY_USER_CONTACT = "VERIFY_USER_CONTACT";
export const VERIFY_USER_CONTACT_SUCCESS = "VERIFY_USER_CONTACT_SUCCESS";
export const VERIFY_USER_CONTACT_ERROR = "VERIFY_USER_CONTACT_ERROR";
