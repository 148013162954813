import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/ConfigureStore";
import CustomizedInput from "../CustomizedInput";
import Button from "../../Button";
import staticData from "../../staticData";
import styles from "./Otp.module.css";

const signUpPage = staticData.signInUpPage;

interface IProps {
  title: string;
  buttonTxt: string;
  authFunction?: any;
  handleResendOtp?: any;
  email?: string;
  mobile?: {
    countryCode: string;
    number: string;
  };
  contactDetails?: any;
  handleChangeClick?: any;
  isSignUpPage?: any;
  onSuccessAuth?: any;
  isCompleteProfilePopup?: boolean;
  isReferralCode: boolean;
}

interface IState {
  otp: string;
}

const Otp: React.FC<IProps> = ({
  title,
  buttonTxt,
  authFunction,
  contactDetails,
  onSuccessAuth,
  isReferralCode = true,
  handleChangeClick,
  isSignUpPage = false,
  handleResendOtp,
  isCompleteProfilePopup = false,
}: IProps) => {
  const [inputValues, setInputValues] = useState({
    otp: "",
    referralCode: "",
  });
  const { loading } = useSelector((state: RootState) => state.auth);

  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { otp, referralCode } = inputValues;
    let mobile = null,
      email = null;
    contactDetails?.countryCode
      ? (mobile = contactDetails)
      : (email = contactDetails);

    let contactType = contactDetails?.countryCode ? "mobile" : "email";
    if (isSignUpPage) {
      authFunction(otp, email, mobile, referralCode);
    } else if (isCompleteProfilePopup) {
      authFunction(
        otp,
        { [contactType]: contactDetails },
        contactType,
        onSuccessAuth
      );
    } else {
      authFunction(otp, email, mobile, onSuccessAuth);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const referralCode = sessionStorage.getItem("referralCode");
    setInputValues((prevState: any) => ({ ...prevState, referralCode }));
  }, []);

  return (
    <>
      <div className={styles.Wrapper}>
        <h2 className={styles.Header}>{title}</h2>
        <div>
          {/* <div className={styles.NoContainer}> */}
          <span className={styles.Number}>
            {contactDetails?.countryCode
              ? contactDetails?.countryCode + contactDetails?.number
              : contactDetails}
          </span>
          <span className={styles.Change} onClick={handleChangeClick}>
            {signUpPage.otpVerify.changeText}
          </span>
          <p className={styles.SubText}>{signUpPage.otpVerify.subTitle} </p>
          {/* </div> */}
        </div>
        <form className={styles.FormWrapper} onSubmit={handleSubmit}>
          <div className={styles.Input}>
            {/* @ts-ignore */}
            <CustomizedInput
              label={signUpPage.otpVerify.inputRequired[0].label}
              type={signUpPage.otpVerify.inputRequired[0].type}
              name={signUpPage.otpVerify.inputRequired[0].name}
              onChange={handleInputChange}
            />
          </div>
          <p className={styles.Resend} onClick={handleResendOtp}>
            {signUpPage.otpVerify.footerText}
            <div className={styles.ResendLink}>
              {signUpPage.otpVerify.linkText}
            </div>
          </p>
          {isReferralCode && (
            <div className={styles.Input}>
              {/* @ts-ignore */}
              <CustomizedInput
                label={signUpPage.otpVerify.inputRequired[1].label}
                type={signUpPage.otpVerify.inputRequired[1].type}
                name={signUpPage.otpVerify.inputRequired[1].name}
                onChange={handleInputChange}
                required={false}
                value={inputValues.referralCode}
              />
            </div>
          )}
          <Button
            text={buttonTxt}
            className={styles.SendBtn}
            isLoading={loading}
            isDisabled={loading}
            disabledClassName={"DisabledBtn"}
          />
        </form>
      </div>
    </>
  );
};

export default Otp;
