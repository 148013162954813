import {
  GET_PRICING_PLAN,
  GET_PRICING_PLAN_ERROR,
  GET_PRICING_PLAN_SUCCESS,
} from './types';

const initialState = {
  isPricingPlansLoading: false,
  taxFilingPlans: [],
  taxReportPlans: [],
  error: null,
};

const pricingPlansReducer = (state = initialState, action) => {
  const { payload } = action;
  
  switch (action.type) {
    case GET_PRICING_PLAN:
      return {
        ...state,
        isPricingPlansLoading: true,
      };
    case GET_PRICING_PLAN_SUCCESS:
      const { taxFilingPlans, taxReportPlans } = payload;
      return {
        ...state,
        isPricingPlansLoading: false,
        taxFilingPlans: taxFilingPlans.length ? taxFilingPlans : state.taxFilingPlans,
        taxReportPlans: taxReportPlans.length ? taxReportPlans : state.taxReportPlans,
      };
    case GET_PRICING_PLAN_ERROR:
      return {
        ...state,
        isPricingPlansLoading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export { pricingPlansReducer };
