export enum TeamActionTypes {
  GET_CHILD_CA_INVITES = "GET_CA_DETAILS",
  GET_CHILD_CA_INVITES_SUCCESS = "GET_CA_DETAILS_SUCCESS",
  GET_CHILD_CA_INVITES_ERROR = "GET_CA_DETAILS_ERROR",

  REVOKED_CHILD_CA = "REVOKED_CHILD_CA",
  REVOKED_CHILD_CA_SUCCESS = "REVOKED_CHILD_CA_SUCCESS",
  REVOKED_CHILD_CA_ERROR = "REVOKED_CHILD_CA_ERROR",

  GET_CHILD_CA_INVITE_DETAILS = "GET_CHILD_CA_INVITE_DETAILS",
  GET_CHILD_CA_INVITE_DETAILS_SUCCESS = "GET_CHILD_CA_INVITE_DETAILS_SUCCESS",
  GET_CHILD_CA_INVITE_DETAILS_ERROR = "GET_CHILD_CA_INVITE_DETAILS_ERROR",

  SEND_MAIL_INVITE = "SEND_MAIL_INVITE",
  SEND_MAIL_INVITE_SUCCESS = "SEND_MAIL_INVITE_SUCCESS",
  SEND_MAIL_INVITE_ERROR = "SEND_MAIL_INVITE_ERROR",
  RESET_INVITE_RESPONSE = "RESET_INVITE_RESPONSE",

  ASSIGN_CLIENT_TO_CHILD_CA = "ASSIGN_CLIENT_TO_CHILD_CA",
  ASSIGN_CLIENT_TO_CHILD_CA_SUCCESS = "ASSIGN_CLIENT_TO_CHILD_CA_SUCCESS",
  ASSIGN_CLIENT_TO_CHILD_CA_ERROR = "ASSIGN_CLIENT_TO_CHILD_CA_ERROR",

  REJECT_INVITE_CHILD_CA = "REJECT_INVITE_CHILD_CA",
  REJECT_INVITE_CHILD_CA_SUCCESS = "REJECT_INVITE_CHILD_CA_SUCCESS",
  REJECT_INVITE_CHILD_CA_ERROR = "REJECT_INVITE_CHILD_CA_ERROR",
}
