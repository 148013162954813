import { AxiosResponse } from "axios";
import { SuccessfulAuthResponse } from "../Interfaces/auth";
import { VALIDATE_SIGNUP_OTP_URL } from "../Utils/constants";
import axios from "./axiosInit";

const validateSignUpOtp = (
  email: string,
  otp: string
): Promise<AxiosResponse<SuccessfulAuthResponse>> => {
  return axios.post(VALIDATE_SIGNUP_OTP_URL, { email, otp });
};

export default validateSignUpOtp;
