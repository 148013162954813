import {
  ICaClient,
  ICaClientNote,
  ICaClientOld,
} from "../../Interfaces/clients";
import { CaClientsActionTypes } from "./types";

type IState = {
  loading: boolean;
  isAddNewClientLoading: boolean;
  isAddBulkClientLoading: boolean;
  isDeleteClientLoading: boolean;
  getAllCaClientsRequestsLoading: boolean;
  isGetAllCaClientsLoading: boolean;
  allCaClients: ICaClient[] | null;
  allCaClientsOld: ICaClientOld[] | null;
  totalClientCount: number;
  allCaClientsRequests: any;
  caClient: ICaClient | null;
  isCaClientLoading: boolean;
  isSendingNote: boolean;
  isUpdateClientStatusLoading: boolean;
  error: any;
  message: any;
};

const initialState: IState = {
  loading: false,
  isAddNewClientLoading: false,
  isAddBulkClientLoading: false,
  isDeleteClientLoading: false,
  getAllCaClientsRequestsLoading: false,
  isGetAllCaClientsLoading: false,
  allCaClients: null,
  allCaClientsOld: null,
  totalClientCount: 0,
  allCaClientsRequests: null,
  caClient: null,
  isCaClientLoading: false,
  isSendingNote: false,
  isUpdateClientStatusLoading: false,
  error: null,
  message: null,
};

const caClientsReducer = (
  state: IState = initialState,
  action: any
): IState => {
  switch (action.type) {
    // delete client
    case CaClientsActionTypes.DELETE_CLIENT:
      return {
        ...state,
        isDeleteClientLoading: true,
      };
    case CaClientsActionTypes.DELETE_CLIENT_ERROR:
      return {
        ...state,
        isDeleteClientLoading: false,
      };
    case CaClientsActionTypes.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        isDeleteClientLoading: false,
        message: action.payload,
      };
    // get all ca client
    case CaClientsActionTypes.GET_ALL_CA_CLIENTS:
      return {
        ...state,
        isGetAllCaClientsLoading: true,
      };
    case CaClientsActionTypes.GET_ALL_CA_CLIENTS_SUCCESS:
      return {
        ...state,
        isGetAllCaClientsLoading: false,
        allCaClients: action.payload.allCaClients,
        totalClientCount: action.payload.totalClientCount,
      };
    case CaClientsActionTypes.GET_ALL_CA_CLIENTS_OLD_SUCCESS:
      return {
        ...state,
        isGetAllCaClientsLoading: false,
        allCaClientsOld: action.payload.allCaClients,
      };
    case CaClientsActionTypes.GET_ALL_CA_CLIENTS_ERROR:
      return {
        ...state,
        isGetAllCaClientsLoading: false,
        error: action.payload,
      };
    // add bulk new client
    case CaClientsActionTypes.ADD_BULK_NEW_CLIENTS:
      return {
        ...state,
        isAddBulkClientLoading: true,
      };
    case CaClientsActionTypes.ADD_BULK_NEW_CLIENTS_ERROR:
      return {
        ...state,
        isAddBulkClientLoading: false,
        error: action.payload,
      };
    case CaClientsActionTypes.ADD_BULK_NEW_CLIENTS_SUCCESS:
      return {
        ...state,
        isAddBulkClientLoading: false,
        message: action.payload,
      };

    case CaClientsActionTypes.SORT_CA_CLIENTS:
      return {
        ...state,
        loading: false,
        allCaClients: action.payload,
      };
    // add new client
    case CaClientsActionTypes.ADD_NEW_CA_CLIENT:
      return {
        ...state,
        isAddNewClientLoading: true,
      };
    case CaClientsActionTypes.ADD_NEW_CA_CLIENT_ERROR:
      return {
        ...state,
        isAddNewClientLoading: false,
        error: action.payload,
      };
    case CaClientsActionTypes.ADD_NEW_CA_CLIENT_SUCCESS:
      return {
        ...state,
        isAddNewClientLoading: false,
        message: action.payload,
      };
    // requests
    case CaClientsActionTypes.GET_ALL_CA_CLIENTS_REQUESTS:
      return {
        ...state,
        getAllCaClientsRequestsLoading: true,
      };
    case CaClientsActionTypes.GET_ALL_CA_CLIENTS_REQUESTS_SUCCESS:
      return {
        ...state,
        getAllCaClientsRequestsLoading: false,
        allCaClientsRequests: action.payload,
      };

    case CaClientsActionTypes.GET_ALL_CA_CLIENTS_REQUESTS_ERROR:
      return {
        ...state,
        getAllCaClientsRequestsLoading: false,
        error: action.payload,
      };

    case CaClientsActionTypes.GET_CA_CLIENT_DETAILS:
      return {
        ...state,
        isCaClientLoading: true,
      };

    case CaClientsActionTypes.GET_CA_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        isCaClientLoading: false,
        caClient: action.payload,
      };

    case CaClientsActionTypes.GET_CA_CLIENT_DETAILS_ERROR:
      return {
        ...state,
        isCaClientLoading: false,
        error: action.payload,
      };

    case CaClientsActionTypes.ADD_CA_CLIENT_NOTES:
      return {
        ...state,
        isSendingNote: true,
      };

    case CaClientsActionTypes.ADD_CA_CLIENT_NOTES_SUCCESS:
      const notes = state.caClient?.notes || [];
      const newNote = action.payload as ICaClientNote;
      return {
        ...state,
        isSendingNote: false,
        caClient: {
          ...state.caClient,
          notes: [...notes, newNote],
        } as ICaClient,
      };

    case CaClientsActionTypes.ADD_CA_CLIENT_NOTES_ERROR:
      return {
        ...state,
        isSendingNote: false,
      };

    case CaClientsActionTypes.UPDATE_CA_CLIENT_STATUS:
      return {
        ...state,
        isUpdateClientStatusLoading: true,
      };

    case CaClientsActionTypes.UPDATE_CA_CLIENT_STATUS_SUCCESS:
      return {
        ...state,
        isUpdateClientStatusLoading: false,
        message: action.payload,
      };

    case CaClientsActionTypes.UPDATE_CA_CLIENT_STATUS_ERROR:
      return {
        ...state,
        isUpdateClientStatusLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export { caClientsReducer };
