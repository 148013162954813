import { combineReducers, compose, legacy_createStore as createStore } from "redux";
import { authReducer } from "./auth/reducers";
import { caClientsReducer } from "./caClients/reducer";
import { CAInvitesReducer } from "./invites/reducers";
import { popupReducer } from "./popup/reducer";
import { userReducer } from "./user/reducer";
import { sourcesReducer } from "./sources/reducer";
import { teamReduce } from "./team/reducers";
import { pricingPlansReducer } from "./pricingPlans/reducer";
import { firmReducer } from "./firm/reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  popup: popupReducer,
  caClients: caClientsReducer,
  invites: CAInvitesReducer,
  user: userReducer,
  source: sourcesReducer,
  team: teamReduce,
  pricingPlans: pricingPlansReducer,
  firm: firmReducer,
});

export const ConfigureStore = () => {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(rootReducer, composeEnhancers());
  return store;
};

export type RootState = ReturnType<typeof rootReducer>;
