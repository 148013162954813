import { useCallback } from "react";
import { useDispatch } from "react-redux";
import useAxiosPrivate from "./useAxiosPrivate";
import {
  setGetNewPricingPlans,
  setGetNewPricingPlansError,
  setGetNewPricingPlansSuccess,
} from "../Redux/pricingPlans/actions";
import { getPricingPlans2 } from "../Services/getPricingPlans";
import notify from "../Utils/notifyToast";
import getErrorStatement from "../Utils/helper/getErrorStatement";

const usePricingPlans = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const handleGetNewPricingPlans = useCallback(
    async (financialYear: string, type: string) => {
      try {
        dispatch(setGetNewPricingPlans());
        const response = await getPricingPlans2(axiosPrivate, {
          financialYear: financialYear,
          type: type,
        });
        const tempResponse = response.data;
        //filter allowed tax reports plans
        tempResponse.taxReportPlans = response.data?.taxReportPlans.filter(
          (plan) => plan.isAllowed
        );
        dispatch(setGetNewPricingPlansSuccess(tempResponse));
      } catch (error: any) {
        dispatch(setGetNewPricingPlansError(getErrorStatement(error)));
        notify(getErrorStatement(error), "error");
      }
    },
    [axiosPrivate, dispatch]
  );

  return {
    handleGetNewPricingPlans,
  };
};
export default usePricingPlans;
