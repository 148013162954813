export const metaTags = {
    signIn: {
        title: "Sign In to KoinX Professional | Access Your Account & Take Your Crypto Tax Experience to the Next Level",
        description: "Take your crypto tax filing experience to a new level with KoinX Professional. Sign in and unleash the power of smart crypto tax calculation.",
        image: ""
    },
    signUp: {
        title: "Sign Up for KoinX Professional | Unlock Advanced Features | Manage Crypto Profile of Your Clients Easily",
        description: "Sign up for KoinX Professional and unlock advanced features. Manage your clients' crypto tax filing with ease. Experience the difference now.",
        image: "https://drive.google.com/file/d/1qmU1MClNIDZhLjA6tfzik5kDNOcRjfLM/view?usp=share_link"
    }
}