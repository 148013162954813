import { USER_CONTACT_VERIFY_URL } from "../../Utils/constants";

const verifyUserContact = (axiosInstance, otp, contact, contactType) => {
  return axiosInstance.post(USER_CONTACT_VERIFY_URL, {
    otp,
    contact,
    contactType,
  });
};

export default verifyUserContact;
