import { AxiosInstance } from "axios";
import {  USER_URL } from "../../Utils/constants";

export const getUser = (axiosInstance: AxiosInstance) => {
  return axiosInstance.get(USER_URL);
};

export const updateUser = (axiosInstance: AxiosInstance, dataToUpdate: any) => {
  return axiosInstance.put(USER_URL, dataToUpdate);
};


