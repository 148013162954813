import { VALIDATE_OTP_URL } from "../../Utils/constants";
import axiosInit from "../axiosInit";

export function validateOTP(otp, email, mobile, referrerCode, inviteId) {
  let body = {
    otp,
    email,
    mobile,
    referrerCode,
    inviteId,
  };

  Object.keys(body).forEach((key) => {
    if (body[key] === undefined || body[key] === null) {
      delete body[key];
    }
  });

  return axiosInit.post(VALIDATE_OTP_URL, body);
}
