import { FirmTeamMember } from "../../Interfaces/firm";
import {
  GET_FIRM_DETAILS,
  GET_FIRM_DETAILS_SUCCESS,
  GET_FIRM_DETAILS_ERROR,
  GET_CLIENT_CAPACITY,
  GET_CLIENT_CAPACITY_SUCCESS,
  GET_CLIENT_CAPACITY_ERROR,
  GET_AVAILABILITY,
  GET_AVAILABILITY_SUCCESS,
  GET_AVAILABILITY_ERROR,
  GET_FIRM_TEAM,
  GET_FIRM_TEAM_SUCCESS,
  GET_FIRM_TEAM_ERROR,
} from "./types";

interface FirmState {
  loading: boolean;
  error: null | string;
  clientCapacity: null | number;
  availability: null | number;
  activeClientCount: null | number;
  teamMembers: FirmTeamMember[] | [];
  teamMemberLoading: boolean;
}

const initialState: FirmState = {
  loading: false,
  error: null,
  clientCapacity: null,
  availability: null,
  activeClientCount: null,
  teamMembers: [],
  teamMemberLoading: false,
};

const firmReducer = (
  state: FirmState = initialState,
  action: any
): FirmState => {
  switch (action.type) {
    case GET_FIRM_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case GET_FIRM_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case GET_FIRM_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CLIENT_CAPACITY:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_CAPACITY_SUCCESS:
      return {
        ...state,
        loading: false,
        clientCapacity: action.payload,
      };
    case GET_CLIENT_CAPACITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_AVAILABILITY:
      return {
        ...state,
        loading: true,
      };
    case GET_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        availability: action.payload,
      };
    case GET_AVAILABILITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_FIRM_TEAM:
      return {
        ...state,
        teamMemberLoading: true,
      };
    case GET_FIRM_TEAM_SUCCESS:
      return {
        ...state,
        teamMemberLoading: false,
        teamMembers: action.payload,
      };
    case GET_FIRM_TEAM_ERROR:
      return {
        ...state,
        teamMemberLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { firmReducer };
