import { PopupActionTypes } from "./types";

export function setPopupOpen(popupOpen: boolean) {
  return {
    type: PopupActionTypes.HANDLE_POPUP_OPEN,
    popupOpen,
  };
}

export function setPopupComponentRender(popupComponent: JSX.Element) {
  return {
    type: PopupActionTypes.HANDLE_POPUP_COMPONENT_RENDER,
    popupComponent,
  };
}

export function setPopupCenterOpen(popupCenterOpen: boolean) {
  return {
    type: PopupActionTypes.HANDLE_POPUP_CENTER_OPEN,
    popupCenterOpen,
  };
}

export function setPopupCenterComponentRender(
  popupCenterComponent: JSX.Element
) {
  return {
    type: PopupActionTypes.HANDLE_POPUP_CENTER_COMPONENT_RENDER,
    popupCenterComponent,
  };
}
