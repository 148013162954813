import { USER_CONTACT_URL } from "../../Utils/constants";

const userContact = (axiosInstance, contact, contactType) => {
  return axiosInstance.put(USER_CONTACT_URL, {
    contact,
    contactType,
  });
};

export default userContact;
