import Enum from "./enum";

//Enums are named in PascalCase because they we are given same from the backend
class UserRoles extends Enum {
  static PARENT_CA_ROLE = "tax_filing_approved";
  static CHILD_CA_ROLE = "child_ca_approved";
  static CA_APPROVED_ROLE = "ca_approved";
}

export const userRoleTypes = Object.freeze(UserRoles);
