class Enum {
  static getAllValues() {
    return Object.values(this);
  }

  static getAllKeys() {
    return Object.keys(this);
  }

  static hasKey(key) {
    return !!this[key];
  }

  static hasValue(value) {
    return Object.values(this).includes(value);
  }
}

export default Enum;
