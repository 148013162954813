import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser } from "../Services/user/getUser";
import { getProfileCompletion } from "../Services/user/getProfileCompletion";
import {
  setFetchUser,
  setFetchUserSuccess,
  setFetchUserFailure,
  setUpdateUserSuccess,
  setFetchUserProfileCompletion,
  setFetchUserProfileCompletionFailure,
  setFetchUserProfileCompletionSuccess,
  setUserContact,
  setUserContactSuccess,
  setUserContactError,
  setVerifyUserContactSuccess,
  setVerifyUserContactError,
  setVerifyUserContact,
} from "../Redux/user/actions";
import { RootState } from "../Redux/ConfigureStore";
import useAxiosPrivate from "./useAxiosPrivate";
import notify from "../Utils/notifyToast";
import userContact from "../Services/user/userContact";
import verifyUserContact from "../Services/user/verifyUserContact";

const useUser = () => {
  const userData = useSelector((state: RootState) => state.user.userData);
  const [user, setUser] = useState(userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const handleFetchUser = (handleUserDataFetch?: any) => {
    dispatch(setFetchUser());
    getUser(axiosPrivate)
      .then((response) => {
        dispatch(setFetchUserSuccess(response.data));
        if (handleUserDataFetch) handleUserDataFetch();
      })
      .catch((error) => {
        dispatch(
          setFetchUserFailure(
            error?.response?.data?.errors[0]
              ? error.response.data.errors[0]
              : "Some server error occured, please try again later!"
          )
        );
        navigate("/");
        notify(
          error?.response?.data?.errors[0]?.message
            ? error.response.data.errors[0].message
            : "Some server error occured, please try again later!",
          "error"
        );
      });
  };

  const handleUpdateUser = useCallback(
    (dataToUpdate: any) => {
      dispatch(setFetchUser());
      updateUser(axiosPrivate, dataToUpdate)
        .then((response) => {
          setUser({ ...user, ...dataToUpdate });
          dispatch(setUpdateUserSuccess(dataToUpdate));
          notify("User updated successfully", "success");
          handleFetchUserProfileCompletion();
          handleFetchUser();
          return response;
        })
        .catch((error) => {
          dispatch(
            setFetchUserFailure(
              error?.response?.data?.errors[0]
                ? error.response.data.errors[0]
                : "Some server error occured, please try again later!"
            )
          );
          handleFetchUser();
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
          return error;
        });
    },
    [dispatch, axiosPrivate, user]
  );

  const handleFetchUserProfileCompletion = () => {
    dispatch(setFetchUserProfileCompletion());
    getProfileCompletion(axiosPrivate)
      .then((response) => {
        dispatch(setFetchUserProfileCompletionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          setFetchUserProfileCompletionFailure(
            error?.response?.data?.errors[0]
              ? error.response.data.errors[0]
              : "Some server error occured, please try again later!"
          )
        );
        notify(
          error?.response?.data?.errors[0]?.message
            ? error.response.data.errors[0].message
            : "Some server error occured, please try again later!",
          "error"
        );
      });
  };

  const handleUserContact = useCallback(
    (contact: any, contactType: any, onSuccessFunction?: any) => {
      dispatch(setUserContact());
      userContact(axiosPrivate, contact, contactType)
        .then((response: any) => {
          dispatch(setUserContactSuccess());
          notify("OTP sent successfully", "success");
          if (onSuccessFunction) {
            onSuccessFunction();
          }
        })
        .catch((error: any) => {
          dispatch(setUserContactError(error));
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch, axiosPrivate]
  );

  const handleVerifyUserContact = useCallback(
    (otp: any, contact: any, contactType: any, onSucessFunction: any) => {
      dispatch(setVerifyUserContact());
      verifyUserContact(axiosPrivate, otp, contact, contactType)
        .then((response: any) => {
          dispatch(setVerifyUserContactSuccess());
          setUser({
            ...user,
            [contactType === "email" ? "email" : "phone"]: contact,
          });
          notify("OTP verified successfully", "success");
          dispatch(
            setUpdateUserSuccess({
              [contactType === "email" ? "email" : "phone"]:
                contact[contactType],
              profileCompleted: true,
            })
          );
          onSucessFunction();
        })
        .catch((error: any) => {
          dispatch(setVerifyUserContactError(error));
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch, axiosPrivate]
  );

  return {
    handleFetchUser,
    handleUpdateUser,
    handleFetchUserProfileCompletion,
    handleVerifyUserContact,
    handleUserContact,
  };
};

export default useUser;
