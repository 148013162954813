import { toast } from "react-toastify";

const notifyToast = (
  message: string,
  type?: any,
  autoClose?: Boolean,
  options?: any
) => {
  toast(message, {
    type,
    autoClose: autoClose ? autoClose : 10000,
    ...options,
  });
};

export default notifyToast;
