export const GET_FIRM_DETAILS = "GET_FIRM_DETAILS";
export const GET_FIRM_DETAILS_SUCCESS = "GET_FIRM_DETAILS_SUCCESS";
export const GET_FIRM_DETAILS_ERROR = "GET_FIRM_DETAILS_ERROR";
export const GET_CLIENT_CAPACITY = "GET_CLIENT_CAPACITY";
export const GET_CLIENT_CAPACITY_SUCCESS = "GET_CLIENT_CAPACITY_SUCCESS";
export const GET_CLIENT_CAPACITY_ERROR = "GET_CLIENT_CAPACITY_ERROR";
export const GET_AVAILABILITY = "GET_AVAILABILITY";
export const GET_AVAILABILITY_SUCCESS = "GET_AVAILABILITY_SUCCESS";
export const GET_AVAILABILITY_ERROR = "GET_AVAILABILITY_ERROR";
export const GET_FIRM_TEAM = "GET_FIRM_TEAM";
export const GET_FIRM_TEAM_SUCCESS = "GET_FIRM_TEAM_SUCCESS";
export const GET_FIRM_TEAM_ERROR = "GET_FIRM_TEAM_ERROR";
