// General Assets
import logo from "../Assets/General/Logo.svg";
import profilePicture from "../Assets/General/ProfilePicture.svg";
import LogoCA from "../Assets/General/LogoCA.svg";
import LogoFooter from "../Assets/General/LogoWhite.svg";
import Listed from "../Assets/General/Listed.svg";
import Edit from "../Assets/General/Edit.svg";
import Iso from "../Assets/General/Iso.png";

//SignInUpPage
import GoogleIcon from "../Assets/SignInUpPage/GoogleLogo.svg";
import BackgroundImage from "../Assets/SignInUpPage/Background.svg";
import LayoutAsset from "../Assets/SignInUpPage/LayoutAsset.svg";

// ClientsPageData
import Binance from "../Assets/ClientsPage/ExchangePlatform/Binance.svg";
import Bitbns from "../Assets/ClientsPage/ExchangePlatform/Bitbns.svg";
import Coinbase from "../Assets/ClientsPage/ExchangePlatform/Coinbase.svg";
import CoinDCX from "../Assets/ClientsPage/ExchangePlatform/CoinDCX.svg";
import CoinSwitch from "../Assets/ClientsPage/ExchangePlatform/CoinSwitch.svg";
import Metamask from "../Assets/ClientsPage/ExchangePlatform/Metamask.svg";
import Vauld from "../Assets/ClientsPage/ExchangePlatform/Vauld.svg";
import WazirX from "../Assets/ClientsPage/ExchangePlatform/WazirX.svg";
import Download_Icon from "../Assets/ClientsPage/ClientsTable/Download_Icon.svg";
import Ellipsis_horizontal from "../Assets/ClientsPage/ClientsTable/Ellipsis_horizontal.svg";
import FilterIcon from "../Assets/General/FilterIcon.svg";
import UploadIcon from "../Assets/General/UploadIcon.svg";
import DeleteIcon from "../Assets/General/DeleteIcon.svg";
import { ReactComponent as UpArrow } from "../Assets/General/UpArrow.svg";

// RequestsPageData
import { ReactComponent as RightCheckMark } from "../Assets/General/RightCheckMark.svg";
import CrossIcon from "../Assets/General/CrossIcon.svg";

//Social Media assets
import Angel from "../Assets/SocialMediaIcons/Angel.svg";
import Facebook from "../Assets/SocialMediaIcons/Facebook.svg";
import Instagram from "../Assets/SocialMediaIcons/Instagram.svg";
import LinkedIn from "../Assets/SocialMediaIcons/LinkedIn.svg";
import Twitter from "../Assets/SocialMediaIcons/Twitter.svg";
import YouTube from "../Assets/SocialMediaIcons/YouTube.svg";
import { userRoleTypes } from "../Utils/enums/UserRoles";

export const images = {
  logo,
  UpArrow,
  LogoCA,
  Listed,
  Edit,
};

const staticData = {
  navbar: {
    categories: (userRole) => {
      switch (userRole) {
        case userRoleTypes.PARENT_CA_ROLE:
          return [
            {
              name: "Tax Filing",
              path: ["/tax-filing"],
            },
            {
              name: "Team",
              path: ["/team"],
            },
            {
              name: "Clients",
              path: ["/clients"],
            },
            {
              name: "Reports",
              path: ["/reports"],
            },
            {
              name: "Requests",
              path: ["/requests"],
            },
          ];
        case userRoleTypes.CHILD_CA_ROLE:
          return [
            {
              name: "Tax Filing",
              path: ["/tax-filing"],
            },
            {
              name: "Clients",
              path: ["/clients"],
            },

            {
              name: "Reports",
              path: ["/reports"],
            },
            {
              name: "Requests",
              path: ["/requests"],
            },
          ];
        case userRoleTypes.CA_APPROVED_ROLE:
          return [
            // {
            //   name: "dashboard",
            //   path: ["/dashboard"],
            // },

            {
              name: "Clients",
              path: ["/clients"],
            },

            {
              name: "Reports",
              path: ["/reports"],
            },
            {
              name: "Requests",
              path: ["/requests"],
            },
            {
              type: "anchor",
              name: "Helpdesk",
              path: "https://care.koinx.com/",
            },
            {
              type: "anchor",
              name: "Blog",
              path: "https://koinx.com/blog",
            },
          ];
        default:
          return [];
      }
    },
    profile: {
      icon: profilePicture,
      name: "profile",
      path: "/settings",
    },
    profileDropdown: {
      dropdownList: [
        {
          label: "Settings",
          path: "/settings",
        },
        {
          type: "anchor",
          label: "Support",
          path: "https://care.koinx.com",
        },

        // {
        //   label: "Contact Us",
        //   path: "/contact",
        // },
      ],
      signout: {
        label: "Signout",
      },
    },
  },
  signInUpPage: {
    BackgroundImage,
    logo,
    LayoutAsset,
    layoutComponentTitle: "Manage crypto portfolios of your clients easily",
    orText: "or",
    googleText: "Continue with Google",
    googleIcon: GoogleIcon,

    businessText: "Not a business user?",
    signUp: {
      subTitle: ["Already have an account?", "Sign In"],
      title: ["Sign Up", "Create Your", "Account"],
      button: {
        googleButton: { text: "SignUp with Google", icon: GoogleIcon },
        continueButton: { text: "Continue" },
        otpButton: { text: "Send Verification Code" },
      },
      orContent: "Or",
      inputRequired: [
        {
          label: "First Name",
          type: "text",
          name: "firstName",
        },
        {
          label: "Last Name",
          type: "text",
          name: "lastName",
        },
        {
          label: "Email",
          type: "email",
          name: "email",
        },
        {
          label: "Organization",
          type: "text",
          name: "caFirmName",

          isOptional: true,
        },
        {
          label: "Referral Code",
          type: "text",
          name: "campaignCode",

          isOptional: true,
        },
        {
          label: "Mobile Number",
          type: "text",
          name: "mobileNumber",
        },
      ],
      passwordText: {
        "Too weak": "var(--primary-red)",
        Weak: "var(--loss-red)",
        Medium: "var(--warning-yellow)",
        Strong: "var(--success-green)",
      },
      footerText: [
        "By signing up, I accept KoinX's ",
        "terms of use ",
        "and ",
        "privacy policy",
      ],
    },
    signIn: {
      subTitle: ["Not a member?", "Sign Up"],
      title: "Get Started",
      button: "Send Verification Code",
      inputRequired: [
        {
          label: "Email or Mobile Number",
          type: "text",
          name: "contact",
        },
      ],
    },
    otpVerify: {
      inputRequired: [
        {
          label: "Enter 6 Digit OTP",
          type: "number",
          name: "otp",
        },
        {
          label: "Referral Code (Optional)",
          type: "text",
          name: "referralCode",
        },
      ],
      subTitle:
        "OTP has been sent to your provided contact. It expires in 5 mins.",
      label: "Enter 6 Digit OTP",
      footerText: "Didn’t Receive?",
      linkText: "Resend OTP",
      changeText: "Change",
    },
  },
  profileSettingsPage: {
    title: "Account Settings",
    inputs: [
      {
        label: "First Name",
        type: "text",
        name: "firstName",
      },
      {
        label: "Last Name",
        type: "text",
        name: "lastName",
      },
      {
        label: "Email",
        type: "email",
        name: "email",
      },
      {
        label: "Mobile",
        type: "number",
        name: "mobile",
      },
      {
        label: "Organization",
        type: "text",
        name: "organization",
      },
      {
        label: "City",
        type: "text",
        name: "city",
      },
      {
        label: "Membership Number",
        type: "text",
        name: "membershipNumber",
      },
      {
        label: "Years of Experience",
        type: "number",
        name: "yearsOfExperience",
      },
      {
        label: "Address",
        type: "text",
        name: "address",
      },
      {
        label: "Website",
        type: "url",
        name: "website",
      },
      {
        label: "Skills",
        type: "text",
        name: "skills",
      },
      {
        label: "About",
        type: "text",
        name: "about",
      },
    ],
    button: ["Edit", "Cancel", "Save"],
    deletePopup: {
      title: "Delete Account",
      subText: [
        "Sorry to see you go, Punit!",
        "Please tell us why you are leaving KoinX so that we can improve the customer experince.",
        "This action deletes all your account data including exchanges, wallets and transactions.This action is irreversible.",
      ],
      feedback: ["Please Provide Feedback", "Tell us why you are leaving"],
      button: ["Cancel", "Delete Account"],
    },
    verifyOtpPopUp: {
      title: "Verify OTP",
      inputs: "Enter OTP Sent to +91 9678384383",
      button: ["Cancel", "Submit"],
    },
    TaxConsultantDetailsComponent: {
      heading: "Tax Consultant Details",
      noInviteSent: {
        subText: "You don’t have any tax consultant added yet. ",
        btnText: "Invite Now",
      },
      invitePending: {
        subText: "Invite Sent To",
      },
      inviteAccepted: {
        note: "Note: Your Tax Consultant can access and edit your portfolio, wallets and transaction data ",
        revokeChip: "Revoke Access",
      },
      inviteDeclined: {
        subText: "Invite Sent To",
        newInviteBtn: "Send request to a new Tax Professional",
        resendInviteBtn: "Resend Request",
      },
      taxConsultantInviteReceived: {
        subText:
          " wants to add your as their client. By accepting this invite, your data will be shared with them. ",
      },
    },
  },
  footer: {
    topMostSection: {
      title: "KoinX",
      logo: LogoFooter,
    },
    copyright: "© All rights reserved by Simplify Infotech Pvt. Ltd.",
    MidSection: [
      {
        heading: "Crypto Taxes for",
        links: [
          {
            name: "Individuals and investors",
            link: "https://app.koinx.com/",
          },
          {
            name: "Tax Professionals and Accountants",
            link: "https://koinx.com/tax-professionals",
          },
          // {
          //   name: "Exchanges and Web3 projects",
          //   link: ""
          // },
        ],
      },
      {
        heading: "Free Tools",
        links: [
          {
            name: "Crypto Prices Live",
            link: "https://www.koinx.com/cryptocurrencies/live-prices",
          },
          {
            name: "Crypto Tax Calculator",
            link: "https://www.koinx.com/crypto-tax-calculator-india",
          },
          // {
          //   name: "Crypto Tax Saving Speculator",
          //   link: "#"
          // },
          {
            name: "Crypto Profit Calculator",
            link: "https://www.koinx.com/crypto-profit-calculator-india",
          },
          {
            name: "Crypto Converter",
            link: "https://www.koinx.com/crypto-converter",
          },
          // {
          //   name: "Crypto Staking ROI Calculator",
          //   link: "#"
          // },
        ],
      },
      {
        heading: "Resource Center",
        links: [
          // {
          //   name: "Crypto Tax Guides",
          //   link: ""
          // },
          // {
          //   name: "Dumb Ways To Lose Money",
          //   link: ""
          // },
          // {
          //   name: "Crypto Tax Savings Guide ",
          //   link: ""
          // },
          {
            name: "Blogs",
            link: "https://www.koinx.com/blog",
          },
          {
            name: "Resources",
            link: "https://www.koinx.com/resources",
          },
          {
            name: "Product Releases",
            link: "https://releases.koinx.com/",
          },
          // {
          //   name: "Crypto Buying Guides",
          //   link: ""
          // },
          // {
          //   name: "Crypto Staking Guides",
          //   link: ""
          // },
          // {
          //   name: "Crypto Mining Guides",
          //   link: ""
          // },
          // {
          //   name: "Crypto Price Predictions",
          //   link: ""
          // },
        ],
      },
      {
        heading: "Help And Support",
        links: [
          // {
          //   name: "Product Guides",
          //   link: ""
          // },
          // {
          //   name: "How To Guides",
          //   link: ""
          // },
          // {
          //   name: "lates",
          //   link: ""
          // },
          {
            name: "Contact us",
            link: "https://care.koinx.com",
          },
        ],
      },
      {
        heading: "Company",
        links: [
          {
            name: "About Us",
            link: "https://www.koinx.com/about-us",
          },
          {
            name: "Backed by",
            link: "https://www.koinx.com/#backed-by",
          },
          // {
          //   name: "Media and Press",
          //   link: ""
          // },
          {
            name: "Careers",
            link: "https://angel.co/company/koinx",
            hireText: "We are Hiring",
          },
          {
            name: "Refund Policy",
            link: "https://www.koinx.com/refund-policy",
          },
          // {
          //   name: "Brand Assets",
          //   link: ""
          // },
          {
            name: "Terms of use",
            link: "https://www.koinx.com/terms-conditions",
          },
          {
            name: "Privacy Policy",
            link: "https://www.koinx.com/privacy-crypto-tax",
          },
        ],
      },
      {
        image: Iso,
      },
    ],
    bottomSection: {
      socialMedia: [
        {
          icon: Angel,
          url: "https://angel.co/company/koinx",
          name: "Angellist",
        },
        {
          icon: LinkedIn,
          url: "https://www.linkedin.com/company/getkoinx",
          name: "LinkedIn",
        },
        {
          icon: Facebook,
          url: "https://www.facebook.com/getkoinx",
          name: "Facebook",
        },
        {
          icon: Instagram,
          url: "https://www.instagram.com/getkoinx",
          name: "Instagram",
        },
        { icon: Twitter, url: "https://twitter.com/getkoinx", name: "Twitter" },
        {
          icon: YouTube,
          url: "https://www.youtube.com/c/CryptoKanoon",
          name: "Youtube",
        },
      ],
    },
  },
};

export const ClientsPageData = {
  Header: {
    Icons: {
      UploadIcon,
    },
  },
  FilterComponent: {
    FilterIcon,
  },
  ClientsTable: {
    exchangeLogos: {
      binance: Binance,
      bitbns: Bitbns,
      coinbase: Coinbase,
      coindcx: CoinDCX,
      coinswitch: CoinSwitch,
      metamask: Metamask,
      vauld: Vauld,
      wazirx: WazirX,
      Vauld: Vauld,
    },
    Icons: {
      DownloadIcon: Download_Icon,
      EllipsisHorizontal: Ellipsis_horizontal,
    },
  },
  ActionsWrapper: {
    buttons: [
      // {
      //   text: "Download Tax Report",
      //   className: "DownloadTaxReportBtn",
      //   icon: DownloadIcon,
      // },
      // {
      //   text: "Mark As Tax Reported",
      //   className: "MarkAsTaxReportedBtn",
      //   icon: ClipboardIcon,
      // },
      {
        text: "Delete",
        className: "DeleteClientDataBtn",
        icon: DeleteIcon,
      },
    ],
  },
  DeleteClientDataPopup: {
    firstScreen: {
      heading:
        "Are you sure you want to remove client data. This action cannot be reverted",
      subHeading:
        "All wallet details, trascations data and tax reports of the clients will be removed.",
    },
    secondScreen: {
      heading: "Enter your firm name in capitals to proceed with deletion",
      subHeading:
        "By proceeding, selected clients data will be removed. You have to add them again.",
      label: "Enter Firm Name (in CAPITALS)",
      placeholder: "ABC TAX CONSULTANCY PRIVATE LIMITED",
    },
  },
  AddNewExchangeMenuItems: {
    items: [
      { text: "Add now", id: "addNow" },
      { text: "Request client to add", id: "reqClient" },
    ],
  },
};

export const RequestsPageData = {
  RequestsReceivedTable: {
    Icons: {
      RightCheckMark,
      CrossIcon,
    },
  },
  RequestsSentTable: {},
};

export const GeneralIcons = {
  Ellipsis_horizontal,
};

export const taxFilingPage = {
  pageHeading: "Dashboard",
  DownloadCSV: "Download CSV",
};

export const teamPageData = {
  teamSectionHeading: "Team",
  inviteButtonText: "Invite Member",
  inviteSectionHeading: "Invited",
  teamTableHeadings: [
    { name: "Name", key: "name" },
    { name: "Email Address", key: "email" },
    { name: "", key: "temp" },
    // { name: "Action", key: "action" },
  ],
  inviteTableHeadings: [
    { name: "Email Address", key: "email" },
    { name: "Status", key: "status" },
    { name: "Invite Link", key: "inviteLink" },
    { name: "Action", key: "action" },
  ],
  inviteMemberPopup: {
    heading: "Invite Member",
    subHeading:
      "Lorem ipsum dolor sit amet consectetur. Tristique augue dui id quam a pellentesque quam posuere elit.",
    inputFieldLabel: "Email Address",
    inviteButton: "Send Invite",
  },
  inviteMemberSuccessPopup: {
    heading: "Email invite sent",
    subHeading:
      "An email invite has been sent to your team member on james.clear@gmail.com. You can send them on the invite link as well",
    copyContent: {
      label: "Invite Link",
      copyBtnText: "Copy",
      copiedText: "Copied",
    },
  },
};

export default staticData;
