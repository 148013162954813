import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAuth from "../../Hooks/useAuth";
import usePopup from "../../Hooks/usePopup";
import useUser from "../../Hooks/useUser";
import { RootState } from "../../Redux/ConfigureStore";
import Button from "../Button";
import OtpComponent from "../Onboarding/OtpComponent";
import PhoneInputComponent from "../PhoneInputComponent";
import staticData from "../staticData";
import styles from "./CompleteProfilePopup.module.css";
import { userRoleTypes } from "../../Utils/enums/UserRoles";

const signUpPage = staticData.signInUpPage;

const CompleteProfilePopup = () => {
  const [caDetails, setCaDetails]: any = useState({
    firstName: "",
    lastName: "",
    caFirmName: "",
  });
  const [isProfileUpdateComponent, setIsProfileUpdateComponent] =
    useState(true);

  const [contactInputValues, setContactInputValues]: any = useState({
    mobile: {
      countryCode: "",
      number: 0,
    },
    email: "",
  });

  const { userData, loading } = useSelector((state: RootState) => state.user);
  const {
    handleUpdateUser,
    handleUserContact,
    handleVerifyUserContact,
    handleFetchUser,
  } = useUser();
  const { handlePopupCenterOpen } = usePopup();
  const { handleResendOTP } = useAuth();

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setCaDetails((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const handleResendOtpFunction = () => {
    // let contactType = userData?.mobile ? "email" : "mobile";
    let mobile = null,
      email = null;
    contactInputValues.mobile.countryCode
      ? (mobile = contactInputValues.mobile)
      : (email = contactInputValues.email);

    handleResendOTP(email, mobile, userData?._id);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleUpdateUser(caDetails);
    let contactType = userData?.mobile ? "email" : "mobile";
    if (
      (contactType === "email" && contactInputValues?.email !== "") ||
      (contactType === "mobile" && contactInputValues?.mobile?.number !== 0)
    ) {
      handleUserContact(
        {
          [contactType]: contactInputValues[contactType],
        },
        contactType
        // handleComponentChange
      );
    }

    handleComponentChange();
  };

  const handleComponentChange = () => {
    setIsProfileUpdateComponent((prev) => !prev);
  };

  const handleSuccessAuth = () => {
    handlePopupCenterOpen(false);
    handleFetchUser();
  };

  const isSubmitDisabled = () => {
    let boolean = false;
    Object.keys(caDetails).forEach((key: string) => {
      if (caDetails[key] === "") {
        boolean = true;
      }
    });
    if (
      contactInputValues.email === "" ||
      !contactInputValues.mobile.number ||
      caDetails.firstName === "KoinX" ||
      caDetails.lastName === "User"
    ) {
      boolean = true;
    }
    return boolean;
  };

  const handleEmailChange = (e: any) => {
    setContactInputValues((prevState: any) => ({
      ...prevState,
      email: e.target.value,
    }));
  };

  useEffect(() => {
    if (userData) {
      setCaDetails((prevState: any) => ({
        ...prevState,
        firstName: userData.firstName === "KoinX" ? "" : userData.firstName,
        lastName: userData.lastName === "User" ? "" : userData.lastName,
        caFirmName: userData?.caFirmName ?? "",
      }));
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.email) {
      setContactInputValues((prevState: any) => ({
        ...prevState,
        email: userData.email,
      }));
    }
    if (userData?.mobile) {
      setContactInputValues((prevState: any) => ({
        ...prevState,
        mobile: {
          countryCode: userData.mobile?.countryCode
            ? userData.mobile?.countryCode
            : "+91",
          number: userData.mobile?.number ? userData.mobile?.number : 0,
        },
      }));
    }
  }, [userData]);

  useEffect(() => {
    if (
      userData &&
      userData["firstName"] &&
      userData["lastName"] &&
      userData["email"] &&
      userData["caFirmName"] &&
      userData["mobile"]
    ) {
      localStorage.removeItem("isNewUser");
      handlePopupCenterOpen(false);
    }
  }, [userData]);

  return (
    <>
      {isProfileUpdateComponent ? (
        <div className={styles.Wrapper}>
          <h1 className={styles.Heading}>Complete Your Profile</h1>
          {userData ? (
            <form className={styles.FormWrapper} onSubmit={handleSubmit}>
              <div className={styles.FormName}>
                <div className={styles.Input}>
                  <span className={`${styles.FormHeading} ${styles.Mandatory}`}>
                    {signUpPage.signUp.inputRequired[0].label}
                  </span>
                  <input
                    name="firstName"
                    placeholder={"First Name"}
                    value={caDetails.firstName}
                    className={styles.FormInputName}
                    onChange={handleChange}
                  />
                </div>
                <div className={styles.Input}>
                  <span className={`${styles.FormHeading} ${styles.Mandatory}`}>
                    {signUpPage.signUp.inputRequired[1].label}
                  </span>
                  <input
                    name="lastName"
                    placeholder={"Last Name"}
                    value={caDetails.lastName}
                    className={styles.FormInputName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={styles.Input}>
                <span className={`${styles.FormHeading} ${styles.Mandatory}`}>
                  {signUpPage.signUp.inputRequired[2].label}
                </span>
                <input
                  name="email"
                  value={contactInputValues.email}
                  disabled={userData?.email ? true : false}
                  className={`${styles.CodeInput} ${
                    userData?.email ? styles.DisabledInput : ""
                  }`}
                  onChange={handleEmailChange}
                />
              </div>
              <PhoneInputComponent
                labelText={signUpPage.signUp.inputRequired[5].label}
                setMobileNum={setContactInputValues}
                value={
                  contactInputValues?.mobile?.countryCode &&
                  contactInputValues?.mobile?.number
                    ? `${contactInputValues?.mobile?.countryCode}${contactInputValues?.mobile?.number}`
                    : "+91"
                }
                className={styles.PhoneInputComponent}
                disabled={userData?.mobile ? true : false}
                isProfileUpdatePopup={true}
                required={true}
              />
              <div className={styles.Input}>
                <span className={`${styles.FormHeading} ${styles.Mandatory}`}>
                  {signUpPage.signUp.inputRequired[3].label}
                </span>
                <input
                  value={caDetails.caFirmName}
                  onChange={handleChange}
                  name="caFirmName"
                  disabled={userData?.roles?.includes(
                    userRoleTypes.CHILD_CA_ROLE
                  )}
                  className={styles.CodeInput}
                ></input>
              </div>
              <Button
                isLoading={loading}
                text={"Submit"}
                className={styles.SendBtn}
                disabledClassName={styles.SendBtnDisabled}
                isDisabled={isSubmitDisabled() || loading}
              />
            </form>
          ) : null}
        </div>
      ) : (
        <div className={styles.Wrapper}>
          <OtpComponent
            title={"Verify Contact Details"}
            buttonTxt={"Submit"}
            contactDetails={
              userData?.mobile
                ? contactInputValues?.email
                : contactInputValues?.mobile
            }
            handleResendOtp={handleResendOtpFunction}
            onSuccessAuth={handleSuccessAuth}
            authFunction={handleVerifyUserContact}
            isCompleteProfilePopup={true}
            handleChangeClick={handleComponentChange}
            isReferralCode={false}
          />
        </div>
      )}
    </>
  );
};

export default CompleteProfilePopup;
