import { InvitesEnumTypes } from "./types";
import { GetCADetailsResponse } from "../../Interfaces/clients";

const initialState = {
  loading: false,
  error: null,
  message: null,
  invitesReceived: null,
  caDetails: null,
  invitesSent: null,
  isAcceptClientInviteLoading: false,
  isCaDetailsLoading: false,
  isRejectClientInviteLoading: false,
};

const CAInvitesReducer = (state: IState = initialState, action: any) => {
  switch (action.type) {
    case InvitesEnumTypes.GET_CA_INVITE_CODE:
      return {
        ...state,
        loading: true,
      };
    // case InvitesEnumTypes.EDIT_CA_INVITE:
    // case InvitesEnumTypes.SEND_INVITE_MAIL_TO_CLIENTS:
    // case InvitesEnumTypes.SEND_BULK_EMAIL_INVITE_TO_CLIENTS:
    case InvitesEnumTypes.GET_INVITES_SENT:
      return {
        ...state,
        loading: true,
      };
    case InvitesEnumTypes.GET_INVITES_SENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case InvitesEnumTypes.GET_INVITES_SENT_SUCCESS:
      return {
        ...state,
        loading: false,
        invitesSent: action.payload,
      };
    case InvitesEnumTypes.GET_CA_DETAILS:
      return {
        ...state,
        isCaDetailsLoading: true,
      };
    case InvitesEnumTypes.GET_CA_DETAILS_ERROR:
      return {
        ...state,
        isCaDetailsLoading: false,
        error: action.payload,
      };
    case InvitesEnumTypes.GET_CA_DETAILS_SUCCESS:
      return {
        ...state,
        isCaDetailsLoading: false,
        caDetails: action.payload,
      };

    case InvitesEnumTypes.REJECT_CLIENT_INVITE:
      return {
        ...state,
        isRejectClientInviteLoading: true,
      };
    case InvitesEnumTypes.REJECT_CLIENT_INVITE_SUCCESS:
      return {
        ...state,
        isRejectClientInviteLoading: false,
        message: action.payload,
      };
    case InvitesEnumTypes.REJECT_CLIENT_INVITE_ERROR:
      return {
        ...state,
        isRejectClientInviteLoading: false,
        error: action.payload,
      };
    case InvitesEnumTypes.ACCEPT_CLIENT_INVITE:
      return {
        ...state,
        isAcceptClientInviteLoading: true,
      };

    case InvitesEnumTypes.ACCEPT_CLIENT_INVITE_SUCCESS:
      return {
        ...state,
        isAcceptClientInviteLoading: false,
      };

    case InvitesEnumTypes.ACCEPT_CLIENT_INVITE_ERROR:
      return {
        ...state,
        isAcceptClientInviteLoading: false,
        message: action.payload,
      };

    // case InvitesEnumTypes.EDIT_CA_INVITE_SUCCESS:
    // case InvitesEnumTypes.SEND_INVITE_MAIL_TO_CLIENTS_SUCCESS:
    // case InvitesEnumTypes.SEND_BULK_EMAIL_INVITE_TO_CLIENTS_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     message: action.payload,
    //   };
    // case InvitesEnumTypes.SEND_INVITE_MAIL_TO_CLIENTS_ERROR:
    // case InvitesEnumTypes.SEND_BULK_EMAIL_INVITE_TO_CLIENTS_ERROR:
    // case InvitesEnumTypes.EDIT_CA_INVITE_ERROR:

    case InvitesEnumTypes.GET_INVITES_RECEIVED:
      return {
        ...state,
        isInvitesReceivedLoading: true,
      };
    case InvitesEnumTypes.GET_INVITES_RECEIVED_ERROR:
      return {
        ...state,
        isInvitesReceivedLoading: false,
      };
    case InvitesEnumTypes.GET_INVITES_RECEIVED_SUCCESS:
      return {
        ...state,
        isInvitesReceivedLoading: false,
        invitesReceived: action.payload,
      };

    case InvitesEnumTypes.GET_CA_INVITE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        inviteCode: action.payload,
      };
    case InvitesEnumTypes.GET_CA_INVITE_CODE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export { CAInvitesReducer };

interface IState {
  loading: boolean;
  error: string | null;
  message: string | null;
  caDetails: GetCADetailsResponse | null;
  invitesSent: any;
  invitesReceived: any;
  isAcceptClientInviteLoading: boolean;
  isRejectClientInviteLoading: boolean;
}
