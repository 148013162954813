import axiosInit from "../axiosInit";
import { BEGIN_AUTH_URL } from "../../Utils/constants";

export const beginAuth = ({ email, mobile, recaptchaToken }) => {
  const body = {
    email,
    mobile,
    recaptchaToken,
  };
  Object.keys(body).forEach((value) => {
    if (body[value] === null || body[value] === undefined) {
      delete body[value];
    }
  });
  return axiosInit.post(BEGIN_AUTH_URL, body);
};
