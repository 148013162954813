import { AuthActionTypes } from "./types";

// signup
export function setSignUp() {
  return {
    type: AuthActionTypes.SIGNUP,
  };
}

export function setSignUpSuccess(message: string) {
  return {
    type: AuthActionTypes.SIGNUP_SUCCESS,
    payload: message,
  };
}

export function setSignUpError(error: any) {
  return {
    type: AuthActionTypes.SIGNUP_ERROR,
    payload: error,
  };
}

// validate signup otp

export function setValidateSignupOtp() {
  return {
    type: AuthActionTypes.VALIDATE_SIGNUP_OTP,
  };
}

export function setValidateSignupOtpSuccess(message: string) {
  return {
    type: AuthActionTypes.VALIDATE_SIGNUP_OTP_SUCCESS,
    payload: message,
  };
}

export function setValidateSignupOtpError(error: string) {
  return {
    type: AuthActionTypes.VALIDATE_SIGNUP_OTP_ERROR,
    payload: error,
  };
}

// signin

export function setSignIn() {
  return {
    type: AuthActionTypes.SIGNIN,
  };
}

export function setSignInSuccess(message: string) {
  return {
    type: AuthActionTypes.SIGNIN_SUCCESS,
    payload: message,
  };
}

export function setSignInError(error: any) {
  return {
    type: AuthActionTypes.SIGNIN_ERROR,
    payload: error,
  };
}

// validate signin otp

export function setValidateSignInOtp() {
  return {
    type: AuthActionTypes.VALIDATE_SIGNIN_OTP,
  };
}

export function setValidateSignInOtpSuccess(message: string) {
  return {
    type: AuthActionTypes.VALIDATE_SIGNIN_OTP_SUCCESS,
    payload: message,
  };
}

export function setValidateSignInOtpError(error: string) {
  return {
    type: AuthActionTypes.VALIDATE_SIGNIN_OTP_ERROR,
    payload: error,
  };
}

// user tokens

export function setHandleUserTokens(accessToken: string | null) {
  return {
    type: AuthActionTypes.HANDLE_USER_TOKENS,
    accessToken,
  };
}

// resend signup otp

export function setResendOtp() {
  return {
    type: AuthActionTypes.RESEND_OTP,
  };
}

export function setResendOtpSuccess(email: string) {
  return {
    type: AuthActionTypes.RESEND_OTP_SUCCESS,
  };
}

export function setResendOtpError(error: string) {
  return {
    type: AuthActionTypes.RESEND_OTP_ERROR,
  };
}

export function setHandleEmailMobile(
  email: string,
  mobile?: { countryCode: string; number: number }
) {
  return {
    type: AuthActionTypes.HANDLE_USER_EMAIL_MOBILE,
    payload: { email, mobile },
  };
}

export function setSignOut() {
  return {
    type: AuthActionTypes.SIGNOUT,
  };
}

export function setSignOutSuccess(message: string) {
  return {
    type: AuthActionTypes.SIGNOUT_SUCCESS,
    payload: message,
  };
}

export function setSignOutError(error: any) {
  return {
    type: AuthActionTypes.SIGNOUT_ERROR,
    payload: error,
  };
}

export function setGenerateClientAuthOTP() {
  return {
    type: AuthActionTypes.GENERATE_CLIENT_AUTH_OTP,
  };
}

export function setGenerateClientAuthOTPSuccess(response: {
  message: string;
  otp: string;
}) {
  return {
    type: AuthActionTypes.GENERATE_CLIENT_AUTH_OTP_SUCCESS,
    payload: response,
  };
}

export function setGenerateClientAuthOTPError(error: any) {
  return {
    type: AuthActionTypes.GENERATE_CLIENT_AUTH_OTP_ERROR,
    payload: error,
  };
}

export function setHandleRefreshToken() {
  return {
    type: AuthActionTypes.HANDLE_REFRESH_TOKEN,
  };
}

export function setHandleRefreshTokenSuccess(accessToken: string) {
  return {
    type: AuthActionTypes.HANDLE_REFRESH_TOKEN_SUCCESS,
    payload: accessToken,
  };
}

export function setHandleRefreshTokenError(error: any) {
  return {
    type: AuthActionTypes.HANDLE_REFRESH_TOKEN_ERROR,
    payload: error,
  };
}

// google sign in
export function setGoogleSignin() {
  return {
    type: AuthActionTypes.GOOGLE_SIGN_IN,
  };
}

export function setGoogleSigninSuccess(message: string) {
  return {
    type: AuthActionTypes.GOOGLE_SIGN_IN_SUCCESS,
    payload: message,
  };
}

export function setGoogleSigninError(error: any) {
  return {
    type: AuthActionTypes.GOOGLE_SIGN_IN_ERROR,
    payload: error,
  };
}

// begin auth

export function setBeginAuth() {
  return {
    type: AuthActionTypes.BEGIN_AUTH,
  };
}

export function setBeginAuthSuccess(message: string, contact: any) {
  return {
    type: AuthActionTypes.BEGIN_AUTH_SUCCESS,
    message: message,
    contact: contact,
  };
}

export function setBeginAuthError(error: any) {
  return {
    type: AuthActionTypes.BEGIN_AUTH_ERROR,
    payload: error,
  };
}

export function setValidateOTP() {
  return {
    type: AuthActionTypes.VALIDATE_OTP,
  };
}

export function setValidateOTPSuccess(message: string, newUser: boolean) {
  return {
    type: AuthActionTypes.VALIDATE_OTP_SUCCESS,
    payload: message,
    newUser: newUser,
  };
}

export function setValidateOTPError(error: any) {
  return {
    type: AuthActionTypes.VALIDATE_OTP_ERROR,
    payload: error,
  };
}
