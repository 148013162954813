import { TextField } from "@mui/material";
import PhoneInputComponent from "../PhoneInputComponent/PhoneInputComponent";
import styles from "./SignInComponent/SignInComponent.module.css";

const inputStyles = {
  "& .MuiFilledInput-root": {
    borderRadius: "0.6rem",
  },
  "& input": {
    fontSize: "var(--font-16)",
    color: "var(--primary-black)",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    fontWeight: 500,
    border: "2px solid transparent",
    "&:-webkit-autofill": {
      WebkitTextFillColor: "var(--sec-black)",
    },
  },
  "& input:focus": {
    backgroundColor: "var(--pure-white)",
    border: "2px solid var(--ter-blue)",
    borderRadius: "0.6rem",
  },
  "& input:disabled": {
    cursor: "not-allowed",
  },
};

const CustomizedInput = ({
  defaultValue,
  name,
  type,
  label,
  isPhoneInput,
  isInputDisabled,
  multiline,
  onChange,
  setMobileNum,
  required = false,
  disabled = false,
  value,
  placeholder,
  min,
  max,
  minRows,
  className,
}) => {
  return (
    <>
      <h3 className={styles.FormHeading}>
        {label}
        {required ? <span className={styles.Astrisk}> *</span> : null}
      </h3>
      <div className={styles.TextFieldWrapper}>
        {isPhoneInput && (
          <div className={styles.PhoneInputWrapper}>
            <PhoneInputComponent
              isInputDisabled={isInputDisabled}
              setMobileNum={setMobileNum}
              className={styles.PhoneInput}
            />
          </div>
        )}
        <TextField
          className={className}
          minRows={minRows}
          disabled={disabled}
          multiline={multiline}
          defaultValue={defaultValue}
          name={name}
          type={type}
          placeholder={placeholder}
          required={required}
          fullWidth
          variant="filled"
          sx={inputStyles}
          InputProps={{
            required: required,
            disableUnderline: true,
            inputProps: {
              min,
              max,
            },
          }}
          onChange={onChange}
          value={value}
        />
      </div>
    </>
  );
};

export default CustomizedInput;
