import React, { useEffect, useState, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import useAuth from "../../Hooks/useAuth";
import Hamburger from "hamburger-react";
import { ReactComponent as ArrowDown } from "../../Assets/General/DownArrow.svg";
import staticData, { images } from "../staticData";
import styles from "./Navbar.module.css";
import useUser from "../../Hooks/useUser";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/ConfigureStore";
import { userRoleTypes } from "../../Utils/enums/UserRoles";

interface IState {
  isOpen: boolean;
  dropdown: boolean;
}

const Navbar: React.FC<{hideProfile?: boolean}> = ({hideProfile = false}) => {
  const [isOpen, setOpen] = useState<IState["isOpen"]>(false);
  const [dropdown, setDropdown] = useState<IState["dropdown"]>(false);
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const { handleSignout } = useAuth();
  const navbarData = staticData.navbar;
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const accordian = document.getElementsByClassName(
      styles.HamburgerCategoriesWrapper
    )[0] as HTMLElement;
    if (isOpen) {
      accordian.style.height = "calc(100vh - 7.5rem)";
      accordian.style.visibility = "visible";
      accordian.style.opacity = "1";
    } else {
      accordian.style.height = "0px";
      accordian.style.visibility = "hidden";
      accordian.style.opacity = "0";
    }

    const dropdownClose = (e: any) => {
      if (dropdown && ref.current && !ref.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", dropdownClose as any);
  }, [isOpen, dropdown]);

  const handleLinkClick = () => {
    setOpen(false);
    setDropdown(false);
  };

  const categoryList = navbarData
    .categories(
      userState?.userData?.roles.includes(userRoleTypes.CHILD_CA_ROLE)
        ? userRoleTypes.CHILD_CA_ROLE
        : userState?.userData?.roles.includes(userRoleTypes.PARENT_CA_ROLE)
        ? userRoleTypes.PARENT_CA_ROLE
        : userState?.userData?.roles.includes(userRoleTypes.CA_APPROVED_ROLE)
        ? userRoleTypes.CA_APPROVED_ROLE
        : ""
    )
    ?.map((category, index) => {
      return (
        <div
          key={index}
          className={
            styles.CategoryWrapper +
            (category.path.includes(pathname)
              ? " " + styles.CategoryWrapperActive
              : "")
          }
          onClick={handleLinkClick}
        >
          {/* {category?.type === "anchor" ? (
          <a
            className={
              styles.Category +
              (category.path?.includes(pathname)
                ? " " + styles.ActiveCategory
                : "")
            }
            href={category.path}
            target="_blank"
            rel="noreferrer"
          >
            {category.name}
          </a>
        ) : ( */}
          <NavLink
            className={
              styles.Category +
              (category.path.includes(pathname)
                ? " " + styles.ActiveCategory
                : "")
            }
            key={index}
            to={category.path[0]}
          >
            {category.name}
          </NavLink>
          {/* )} */}
        </div>
      );
    });

  return (
    <div className={styles.Navbar}>
      <div className={styles.Wrapper}>
        <div className={styles.LogoCategoriesWrapper}>
          <Link to="/clients">
            <img src={images.LogoCA} alt="logo" className={styles.Logo} />
          </Link>
          <div className={styles.CategoriesWrapper}>{categoryList}</div>
        </div>
        {!hideProfile ? <div
          className={styles.ProfileWrapper}
          onClick={() => {
            setDropdown(!dropdown);
            setOpen(!isOpen);
          }}
        >
          <div className={styles.ProfileContainer}>
            <img
              src={navbarData.profile.icon}
              alt="profile"
              className={styles.Profile}
            />
            <span className={styles.ProfileName}>
              {userState?.userData?.firstName} {userState?.userData?.lastName}
            </span>
          </div>

          <ArrowDown className={styles.ArrowDown} />
        </div> : null}
        {dropdown && (
          <div className={styles.Dropdown} ref={ref}>
            {navbarData.profileDropdown.dropdownList.map((item) => (
              <Link
                key={item.label}
                to={`${item.path}`}
                target={item?.type === "anchor" ? "_blank" : "_self"}
                className={styles.DropdownItems}
                onClick={() => setDropdown(false)}
              >
                {item.label}
              </Link>
            ))}
            <hr className={styles.Line} />
            <p className={styles.DropdownItems} onClick={handleSignout}>
              {navbarData.profileDropdown.signout.label}
            </p>
          </div>
        )}

        {!hideProfile ? <div className={styles.Hamburger}>
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            rounded
            size={20}
            color="var(--primary-blue)"
          />
        </div> : null}
      </div>
      <hr className={styles.BorderBottom} />
      <div
        className={styles.HamburgerCategoriesWrapper}
        style={{
          height: "0",
          visibility: "hidden",
          opacity: "0",
        }}
      >
        {categoryList}
        <div className={styles.LowerNavWrapper}>
          <NavLink
            className={({ isActive }) =>
              styles.Category + (isActive ? " " + styles.ActiveCategory : "")
            }
            to={navbarData.profile.path}
            onClick={handleLinkClick}
          >
            {navbarData.profileDropdown.dropdownList[0].label}
          </NavLink>
          <div className={styles.Category} onClick={handleSignout}>
            {navbarData.profileDropdown.signout.label}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
