export enum InvitesEnumTypes {
  ACCEPT_CLIENT_INVITE = "ACCEPT_CLIENT_INVITE",
  ACCEPT_CLIENT_INVITE_SUCCESS = "ACCEPT_CLIENT_INVITE_SUCCESS",
  ACCEPT_CLIENT_INVITE_ERROR = "ACCEPT_CLIENT_INVITE_ERROR",

  REJECT_CLIENT_INVITE = "REJECT_CLIENT_INVITE",
  REJECT_CLIENT_INVITE_SUCCESS = "REJECT_CLIENT_INVITE_SUCCESS",
  REJECT_CLIENT_INVITE_ERROR = "REJECT_CLIENT_INVITE_ERROR",

  GET_CA_INVITE_CODE = "GET_CA_INVITE_CODE",
  GET_CA_INVITE_CODE_SUCCESS = "GET_CA_INVITE_CODE_SUCCESS",
  GET_CA_INVITE_CODE_ERROR = "GET_CA_INVITE_CODE_ERROR",

  EDIT_CA_INVITE = "EDIT_CA_INVITE",
  EDIT_CA_INVITE_SUCCESS = "EDIT_CA_INVITE_SUCCESS",
  EDIT_CA_INVITE_ERROR = "EDIT_CA_INVITE_ERROR",

  GET_CA_DETAILS = "GET_CA_DETAILS",
  GET_CA_DETAILS_SUCCESS = "GET_CA_DETAILS_SUCCESS",
  GET_CA_DETAILS_ERROR = "GET_CA_DETAILS_ERROR",

  SEND_INVITE_MAIL_TO_CLIENTS = "SEND_INVITE_MAIL_TO_CLIENTS",
  SEND_INVITE_MAIL_TO_CLIENTS_SUCCESS = "SEND_INVITE_MAIL_TO_CLIENTS_SUCCESS",
  SEND_INVITE_MAIL_TO_CLIENTS_ERROR = "SEND_INVITE_MAIL_TO_CLIENTS_ERROR",

  GET_INVITES_RECEIVED = "GET_INVITES_RECEIVED",
  GET_INVITES_RECEIVED_SUCCESS = "GET_INVITES_RECEIVED_SUCCESS",
  GET_INVITES_RECEIVED_ERROR = "GET_INVITES_RECEIVED_ERROR",

  GET_INVITES_SENT = "GET_INVITES_SENT",
  GET_INVITES_SENT_SUCCESS = "GET_INVITES_SENT_SUCCESS",
  GET_INVITES_SENT_ERROR = "GET_INVITES_SENT_ERROR",

  SEND_BULK_EMAIL_INVITE_TO_CLIENTS = "SEND_BULK_EMAIL_INVITE_TO_CLIENTS",
  SEND_BULK_EMAIL_INVITE_TO_CLIENTS_SUCCESS = "SEND_BULK_EMAIL_INVITE_TO_CLIENTS_SUCCESS",
  SEND_BULK_EMAIL_INVITE_TO_CLIENTS_ERROR = "SEND_BULK_EMAIL_INVITE_TO_CLIENTS_ERROR",
}
