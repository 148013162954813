import Enum from "./enum";

//Enums are named in PascalCase because they we are given same from the backend
class StatusTypes extends Enum {
  static pending = "Pending";
  static in_progress = "In Progress";
  static done = "Done";
  static on_hold = "On Hold";
  static upgrade_needed = "Upgrade Needed";
}

export const statusTypes = Object.freeze(StatusTypes);