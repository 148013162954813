import { RESEND_OTP_URL } from "../../Utils/constants";
import axiosInit from "../axiosInit";

export function resendOTP(email, mobile, userId) {
  const body = {
    email,
    mobile,
    userId,
  };

  Object.keys(body).forEach((key) => {
    if (body[key] === undefined || body[key] === null) {
      delete body[key];
    }
  });

  return axiosInit.post(RESEND_OTP_URL, body);
}
