import { AuthActionTypes } from "./types";

const initialState = {
  loading: false,
  error: null,
  message: null,
  accessToken: null,
  clientLoginOTP: null,
  email: null,
  mobile: null,
};

const authReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case AuthActionTypes.VALIDATE_OTP: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.VALIDATE_OTP_SUCCESS: {
      return {
        ...state,
        loading: false,
        message: action.payload,
        newUser: action.newUser,
      };
    }
    case AuthActionTypes.VALIDATE_OTP_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case AuthActionTypes.BEGIN_AUTH: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.BEGIN_AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        message: action.message,
        contact: action.contact,
      };
    }
    case AuthActionTypes.BEGIN_AUTH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case AuthActionTypes.GOOGLE_SIGN_IN_SUCCESS:
    case AuthActionTypes.GOOGLE_SIGN_UP_SUCCESS: {
      return {
        ...state,
        googleSignInLoading: false,
        message: action.payload,
      };
    }
    case AuthActionTypes.GOOGLE_SIGN_UP_ERROR:
    case AuthActionTypes.GOOGLE_SIGN_IN_ERROR: {
      return {
        ...state,
        googleSignInLoading: false,
        error: action.payload,
      };
    }
    case AuthActionTypes.GOOGLE_SIGN_UP:
    case AuthActionTypes.GOOGLE_SIGN_IN: {
      return {
        ...state,
        googleSignInLoading: true,
      };
    }
    case AuthActionTypes.SIGNUP:
    case AuthActionTypes.SIGNIN:
    case AuthActionTypes.SIGNOUT:
    case AuthActionTypes.VALIDATE_SIGNUP_OTP:
    case AuthActionTypes.VALIDATE_SIGNIN_OTP:
    case AuthActionTypes.GENERATE_CLIENT_AUTH_OTP:
    case AuthActionTypes.HANDLE_REFRESH_TOKEN:
      return {
        ...state,
        loading: true,
      };
    case AuthActionTypes.SIGNUP_SUCCESS:
    case AuthActionTypes.SIGNIN_SUCCESS:
    case AuthActionTypes.SIGNOUT_SUCCESS:
    case AuthActionTypes.VALIDATE_SIGNUP_OTP_SUCCESS:
    case AuthActionTypes.VALIDATE_SIGNIN_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case AuthActionTypes.SIGNUP_ERROR:
    case AuthActionTypes.SIGNIN_ERROR:
    case AuthActionTypes.SIGNOUT_ERROR:
    case AuthActionTypes.VALIDATE_SIGNUP_OTP_ERROR:
    case AuthActionTypes.VALIDATE_SIGNIN_OTP_ERROR:
    case AuthActionTypes.GENERATE_CLIENT_AUTH_OTP_ERROR:
    case AuthActionTypes.HANDLE_REFRESH_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AuthActionTypes.RESEND_OTP:
    case AuthActionTypes.RESEND_SIGNIN_OTP:
      return {
        ...state,
        loading: true,
      };
    case AuthActionTypes.RESEND_OTP_SUCCESS:
    case AuthActionTypes.RESEND_SIGNIN_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case AuthActionTypes.RESEND_OTP_ERROR:
    case AuthActionTypes.RESEND_SIGNIN_OTP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case AuthActionTypes.HANDLE_USER_TOKENS:
      return {
        ...state,
        accessToken: action.accessToken,
      };
    case AuthActionTypes.GENERATE_CLIENT_AUTH_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        clientLoginOTP: action.payload.otp,
      };
    case AuthActionTypes.HANDLE_USER_EMAIL_MOBILE:
      return {
        ...state,
        email: action.payload.email,
        mobile: action.payload.mobile,
      };
    case AuthActionTypes.HANDLE_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        accessToken: action.payload,
      };
    default:
      return state;
  }
};

export { authReducer };
