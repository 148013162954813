export enum CaClientsActionTypes {
  ADD_NEW_CA_CLIENT = "ADD_NEW_CA_CLIENT",
  ADD_NEW_CA_CLIENT_SUCCESS = "ADD_NEW_CA_CLIENT_SUCCESS",
  ADD_NEW_CA_CLIENT_ERROR = "ADD_NEW_CA_CLIENT_ERROS",

  GET_ALL_CA_CLIENTS = "GET_ALL_CA_CLIENTS",
  GET_ALL_CA_CLIENTS_SUCCESS = "GET_ALL_CA_CLIENTS_SUCCESS",
  GET_ALL_CA_CLIENTS_OLD_SUCCESS = "GET_ALL_CA_CLIENTS_OLD_SUCCESS",
  GET_ALL_CA_CLIENTS_ERROR = "GET_ALL_CA_CLIENTS_ERROS",

  GET_ALL_CA_CLIENTS_REQUESTS = "GET_ALL_CA_CLIENTS_REQUESTS",
  GET_ALL_CA_CLIENTS_REQUESTS_SUCCESS = "GET_ALL_CA_CLIENTS_REQUESTS_SUCCESS",
  GET_ALL_CA_CLIENTS_REQUESTS_ERROR = "GET_ALL_CA_CLIENTS_REQUESTS_ERROR",

  // delete client
  DELETE_CLIENT = "DELETE_CLIENT",
  DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR",

  SORT_CA_CLIENTS = "SORT_CA_CLIENTS",

  //  bulk add
  ADD_BULK_NEW_CLIENTS = "ADD_BULK_NEW_CLIENTS",
  ADD_BULK_NEW_CLIENTS_SUCCESS = "ADD_BULK_NEW_CLIENTS_SUCCESS",
  ADD_BULK_NEW_CLIENTS_ERROR = "GET_ALL_CA_CLIENTS_ERROS",

  //  get client details
  GET_CA_CLIENT_DETAILS = "GET_CA_CLIENT_DETAILS",
  GET_CA_CLIENT_DETAILS_SUCCESS = "GET_CA_CLIENT_DETAILS_SUCCESS",
  GET_CA_CLIENT_DETAILS_ERROR = "GET_CA_CLIENT_DETAILS_ERROR",

  // send client notes
  ADD_CA_CLIENT_NOTES = "ADD_CA_CLIENT_NOTES",
  ADD_CA_CLIENT_NOTES_SUCCESS = "ADD_CA_CLIENT_NOTES_SUCCESS",
  ADD_CA_CLIENT_NOTES_ERROR = "ADD_CA_CLIENT_NOTES_ERROR",

  // update client status
  UPDATE_CA_CLIENT_STATUS = "UPDATE_CA_CLIENT_STATUS",
  UPDATE_CA_CLIENT_STATUS_SUCCESS = "UPDATE_CA_CLIENT_STATUS_SUCCESS",
  UPDATE_CA_CLIENT_STATUS_ERROR = "UPDATE_CA_CLIENT_STATUS_ERROR",
}
