import { TeamActionTypes } from "./types";
import { ChildCAInvite, InviteResponse } from "../../Interfaces/team";
interface IState {
  invitesLoading: boolean;
  error: string | null;
  message: string | null;
  invitesSent: ChildCAInvite[] | null;
  inviteResponse: InviteResponse | null;
  inviteResponseLoading: boolean;
  revokeChildCAInviteLoading: boolean;
  childCAInviteDetails: ChildCAInvite | null;
  isChildCAInviteDetailsLoading: boolean;
  isAssignClientToChildCALoading: boolean;
  isInviteRejectedLoading: boolean;
}

const initialState: IState = {
  invitesLoading: false,
  error: null,
  message: null,
  invitesSent: null,
  inviteResponse: null,
  inviteResponseLoading: false,
  revokeChildCAInviteLoading: false,
  childCAInviteDetails: null,
  isChildCAInviteDetailsLoading: false,
  isAssignClientToChildCALoading: false,
  isInviteRejectedLoading: false,
};

const teamReduce = (state = initialState, action: any): IState => {
  switch (action.type) {
    case TeamActionTypes.GET_CHILD_CA_INVITES:
      return {
        ...state,
        invitesLoading: true,
      };
    case TeamActionTypes.GET_CHILD_CA_INVITES_SUCCESS:
      return {
        ...state,
        invitesLoading: false,
        invitesSent: action.payload,
      };
    case TeamActionTypes.GET_CHILD_CA_INVITES_ERROR:
      return {
        ...state,
        invitesLoading: false,
        error: action.payload,
      };
    case TeamActionTypes.REVOKED_CHILD_CA:
      return {
        ...state,
        revokeChildCAInviteLoading: true,
      };
    case TeamActionTypes.REVOKED_CHILD_CA_SUCCESS:
      return {
        ...state,
        revokeChildCAInviteLoading: false,
        message: action.payload,
      };
    case TeamActionTypes.REVOKED_CHILD_CA_ERROR:
      return {
        ...state,
        revokeChildCAInviteLoading: false,
        error: action.payload,
      };
    case TeamActionTypes.GET_CHILD_CA_INVITE_DETAILS:
      return {
        ...state,
        isChildCAInviteDetailsLoading: true,
      };
    case TeamActionTypes.GET_CHILD_CA_INVITE_DETAILS_SUCCESS:
      return {
        ...state,
        isChildCAInviteDetailsLoading: false,
        childCAInviteDetails: action.payload,
      };
    case TeamActionTypes.GET_CHILD_CA_INVITE_DETAILS_ERROR:
      return {
        ...state,
        isChildCAInviteDetailsLoading: false,
        error: action.payload,
      };
    case TeamActionTypes.SEND_MAIL_INVITE:
      return {
        ...state,
        inviteResponseLoading: true,
      };
    case TeamActionTypes.SEND_MAIL_INVITE_SUCCESS:
      return {
        ...state,
        inviteResponseLoading: false,
        inviteResponse: action.payload,
      };
    case TeamActionTypes.SEND_MAIL_INVITE_ERROR:
      return {
        ...state,
        inviteResponseLoading: false,
        error: action.payload,
      };
    case TeamActionTypes.RESET_INVITE_RESPONSE:
      return {
        ...state,
        inviteResponse: null,
      };
    case TeamActionTypes.ASSIGN_CLIENT_TO_CHILD_CA:
      return {
        ...state,
        isAssignClientToChildCALoading: true,
      };
    case TeamActionTypes.ASSIGN_CLIENT_TO_CHILD_CA_SUCCESS:
      return {
        ...state,
        isAssignClientToChildCALoading: false,
        message: action.payload,
      };
    case TeamActionTypes.ASSIGN_CLIENT_TO_CHILD_CA_ERROR:
      return {
        ...state,
        isAssignClientToChildCALoading: false,
        error: action.payload,
      };
    case TeamActionTypes.REJECT_INVITE_CHILD_CA:
      return {
        ...state,
        isInviteRejectedLoading: true,
      };
    case TeamActionTypes.REJECT_INVITE_CHILD_CA_SUCCESS:
      return {
        ...state,
        isInviteRejectedLoading: false,
        message: action.payload,
      };
    case TeamActionTypes.REJECT_INVITE_CHILD_CA_ERROR:
      return {
        ...state,
        error: action.payload,
        isInviteRejectedLoading: false,
      };
    default:
      return state;
  }
};

export { teamReduce };
