import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Outlet, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import { RootState } from './Redux/ConfigureStore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { IPopupState } from './Redux/popup/reducer';
import { Navigate, useNavigate } from 'react-router';
import useAuth from './Hooks/useAuth';
import { metaTags } from './Utils/helper/metaTags';
import usePopup from './Hooks/usePopup';
import usePricingPlans from './Hooks/usePricingPlans';
import { userRoleTypes } from './Utils/enums/UserRoles';
import useUser from './Hooks/useUser';
import { lazyWithRetry } from './Utils/lazyWithRetry';

// Components
import Navbar from './Components/Navbar';
import LoaderWrapper from './Components/LoaderWrapper';
import PopUpCenter from './Components/PopupCenter/PopupCenter';
import Footer from './Components/Footer';
import CompleteProfilePopup from './Components/CompleteProfilePopup';

// Containers (Pages)
const Clients = lazyWithRetry(() => import('./Containers/Clients'));
const Requests = lazyWithRetry(() => import('./Containers/Requests'));
const Reports = lazyWithRetry(() => import('./Containers/Reports/Reports'));
const Settings = lazyWithRetry(() => import('./Containers/Settings'));
const SignIn = lazyWithRetry(() => import('./Containers/Onboarding/SignIn'));
const OTPPage = lazyWithRetry(() => import('./Containers/Onboarding/OTPPage'));
const VerifyOTPPage = lazyWithRetry(
  () => import('./Containers/Onboarding/VerifyOTPPage')
);
const TaxFiling = lazyWithRetry(() => import('./Containers/TaxFiling'));
const ClientDetails = lazyWithRetry(() => import('./Containers/ClientDetails'));
const Team = lazyWithRetry(() => import('./Containers/Team'));
const RejectInvite = lazyWithRetry(
  () => import('./Containers/Onboarding/RejectInvite')
);

const App = () => {
  const [isAppInitialized, setIsAppInitialized] = React.useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const { handlePopupCenterOpen, handlePopupCenterComponentRender } =
    usePopup();
  const { handleRefreshToken } = useAuth();
  const {
    popupCenterComponent: popUpCenterComponentToRender,
    popupCenterOpen: popUpCenterState,
  } = useSelector<RootState>((state) => state.popup) as IPopupState;
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const { handleGetNewPricingPlans } = usePricingPlans();
  const { handleFetchUser } = useUser();

  useEffect(() => {
    if (!accessToken) {
      handleRefreshToken(setIsAppInitialized);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      setIsAppInitialized(false);
      const dataFetchingStatesLocal = {
        userData: false,
      };

      const handleDataFetchStatusUpdation =
        (key: keyof typeof dataFetchingStatesLocal) => () => {
          dataFetchingStatesLocal[key] = true;
          if (
            Object.values(dataFetchingStatesLocal).every(
              (value) => value === true
            )
          ) {
            setIsAppInitialized(true);
          }
        };

      handleFetchUser(handleDataFetchStatusUpdation('userData'));
      const currentYear = new Date().getFullYear();
      handleGetNewPricingPlans(currentYear.toString(), 'tax-filing');
      handleGetNewPricingPlans(currentYear.toString(), 'tax-report');
    }
  }, [accessToken]);

  useEffect(() => {
    const inviteId = searchParams.get('inviteId');
    const inviteAction = searchParams.get('action');
    const referralCode = searchParams.get('r');
    if (referralCode) {
      sessionStorage.setItem('referralCode', referralCode);
    }
    if (inviteAction === 'reject') {
      navigate('/reject-invite/' + inviteId);
    }
    if (inviteId) {
      sessionStorage.setItem('inviteId', inviteId);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      if (
        !userData?.mobile?.number ||
        !userData?.email ||
        !userData?.caFirmName
      ) {
        handlePopupCenterComponentRender(<CompleteProfilePopup />);
        handlePopupCenterOpen(true);
      } else {
        handlePopupCenterOpen(false);
      }
    }
  }, [userData]);

  const publicRoutes = () => {
    return (
      <Route
        element={
          <>
            <Outlet />
          </>
        }>
        {/* <Route
          path="/signup"
          element={
            <SignUp
              title={metaTags.signUp.title}
              description={metaTags.signUp.description}
              image={metaTags.signUp.image}
            />
          }
        /> */}
        <Route
          path="/get-started"
          element={
            <SignIn
              title={metaTags.signIn.title}
              description={metaTags.signIn.description}
              image={metaTags.signIn.image}
            />
          }
        />
        <Route path="/otp" element={<OTPPage />} />
        <Route path="/verifyotp" element={<VerifyOTPPage />} />
        <Route path="/reject-invite/:id" element={<RejectInvite />} />
        <Route path="*" element={<Navigate to="/get-started" />} />
      </Route>
    );
  };

  const privateRoutes = () => {
    if (userData?.roles?.includes(userRoleTypes.PARENT_CA_ROLE)) {
      return (
        <Route
          element={
            <>
              <Navbar />
              <Outlet />
            </>
          }>
          <Route path="/settings" element={<Settings />} />
          <Route path="/tax-filing" element={<TaxFiling />} />
          <Route path="/client/:id" element={<ClientDetails />} />
          <Route path="/team" element={<Team />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/" element={<Navigate to={'/tax-filing'} />} />
          <Route path="*" element={<Navigate to={'/tax-filing'} />} />
        </Route>
      );
    } else if (userData?.roles?.includes(userRoleTypes.CHILD_CA_ROLE)) {
      return (
        <Route
          element={
            <>
              <Navbar />
              <Outlet />
            </>
          }>
          <Route path="/settings" element={<Settings />} />
          <Route path="/tax-filing" element={<TaxFiling />} />
          <Route path="/client/:id" element={<ClientDetails />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/" element={<Navigate to={'/tax-filing'} />} />
          <Route path="*" element={<Navigate to={'/tax-filing'} />} />
        </Route>
      );
    } else {
      return (
        <Route
          element={
            <>
              <Navbar />
              <Outlet />
            </>
          }>
          <Route path="/reports" element={<Reports />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<Navigate to={'/clients'} />} />
        </Route>
      );
    }
  };

  return (
    <>
      <HelmetProvider>
        {isAppInitialized ? (
          <div>
            <ToastContainer
              bodyClassName="ToastifyToastBody"
              style={{ fontSize: '1.5rem' }}
            />
            <PopUpCenter
              ContentComp={popUpCenterComponentToRender}
              isOpen={popUpCenterState}
            />
            <Suspense fallback={<LoaderWrapper />}>
              {/* Route protection is done over here */}
              <Routes>{userData ? privateRoutes() : publicRoutes()}</Routes>
              <Footer />
            </Suspense>
          </div>
        ) : (
          <LoaderWrapper />
        )}
      </HelmetProvider>
    </>
  );
};

export default App;
