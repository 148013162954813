import {
  GET_PRICING_PLAN,
  GET_PRICING_PLAN_ERROR,
  GET_PRICING_PLAN_SUCCESS,
} from './types';

export const setGetNewPricingPlans = () => {
  return {
    type: GET_PRICING_PLAN,
  };
};
export const setGetNewPricingPlansSuccess = (data) => {
  return {
    type: GET_PRICING_PLAN_SUCCESS,
    payload: data,
  };
};
export const setGetNewPricingPlansError = (error) => {
  return {
    type: GET_PRICING_PLAN_ERROR,
    payload: error,
  };
};
