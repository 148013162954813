import { statusTypes } from "./enums/StatusTypes";

export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const CA_URL = `/ca`;
export const AUTH_URL = `/auth`;
export const CLIENTS_URL = "/clients";

// auth constants
export const SIGNUP_URL = `${CA_URL}${AUTH_URL}/signUp`;
export const SIGNIN_URL = `${CA_URL}${AUTH_URL}/sendSignInOtp`;
// this constant is same as frontend
export const SIGNOUT_URL = `${CA_URL}${AUTH_URL}/signout`;
export const REFRESH_TOKEN_URL = `${CA_URL}${AUTH_URL}/token`;

export const RESEND_OTP_URL = `${CA_URL}${AUTH_URL}/resendAuthOTP`;
export const SEND_SIGNIN_OTP_URL = `${CA_URL}${AUTH_URL}/sendSignInOTP`;
export const GENERATE_CLIENT_AUTH_OTP_URL = `${CA_URL}${AUTH_URL}/generateClientAuthOTP`;

export const VALIDATE_SIGNUP_OTP_URL = `${CA_URL}${AUTH_URL}/validateAuthOTP`;
export const VALIDATE_SIGNIN_OTP_URL = `${CA_URL}${AUTH_URL}/validateAuthOTP`;

// client constants
export const GET_ALL_CA_CLIENTS_URL = `${CA_URL}/clients`;
export const GET_ALL_CA_CLIENTS_REQUESTS_URL = `${CA_URL}/clients/requests`;
export const DELETE_CLIENT_URL = `${CA_URL}/clients/`;
export const ADD_BULK_NEW_CLIENTS = `${CA_URL}${CLIENTS_URL}/bulkCreate`;
export const ADD_NEW_CLIENT_URL = `${CA_URL}/clients`;
export const GET_CA_CLIENT_URL = `${CA_URL}/clients/:clientId`;
export const ADD_CA_CLIENT_NOTES_URL = `${CA_URL}/clients/:clientId/notes`;
export const UPDATE_CA_CLIENT_STATUS_URL = `${CA_URL}/clients/:clientId/status`;

// invite constants
export const GET_CA_DETAILS_URL = `${CA_URL}/invites/details`;
export const CA_INVITE_URL = `${CA_URL}/invites`;

export const ACCEPT_CLIENT_INVITE_URL = `${CA_URL}/invites/accept`;
export const REJECT_CLIENTS_INVITE_URL = `${CA_URL}/invites/reject`;

export const INVITES_RECEIVED_URL = `${CA_URL}/invites/received`;
export const INVITES_SENT_URL = `${CA_URL}/invites/sent`;
export const USER_URL = `/user`;
export const SEND_BULK_EMAIL_INVITE_TO_CLIENTS = `${CA_URL}/invites/bulkMail`;
export const PROFILE_COMPLETION = `${CA_URL}${USER_URL}/profile-completion`;
export const GET_LISTED_TO_DIRECTORY = `${CA_URL}${USER_URL}/directory`;

//Integrations
export const SOURCES_URL = `${BASE_URL}/sources`;

//Dev-app
export const MAIN_APP_URL =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://app.koinx.com"
    : process.env.REACT_APP_ENVIRONMENT === "development"
    ? "https://dev-app.koinx.com"
    : "http://localhost:3000";
// Google Auth
export const GOOGLE_SIGNUP_URL = `${AUTH_URL}/googleSignup`;
export const GOOGLE_SIGNIN_URL = `${AUTH_URL}/googleSignin`;
// new auth
export const BEGIN_AUTH_URL = `${CA_URL}${AUTH_URL}/beginAuth`;
export const VALIDATE_OTP_URL = `${CA_URL}${AUTH_URL}/validateAuthOTP`;

// user
export const USER_CONTACT_URL = `${USER_URL}/contact`;
export const USER_CONTACT_VERIFY_URL = `${USER_CONTACT_URL}/verify`;

// Firm-Team
const CHILD_CA = `${CA_URL}/ca-child`;
export const CHILD_CA_INVITE_URL = `${CHILD_CA}/invites`;
export const REVOKE_CHILD_CA_INVITE_URL = `${CHILD_CA}/:inviteId/revoke`;
export const CHILD_CA_INVITE_DETAILS_URL = `${CHILD_CA}/:inviteId/details`;
export const CHILD_CA_MAIL_INVITE_URL = `${CHILD_CA}/mail`;
export const CHILD_CA_ASSIGN_CLIENT = `${CHILD_CA}/assign-client`;
export const REJECT_INVITE_URL = `${CHILD_CA}/:inviteId/reject`;

// pricing plan
export const GET_PRICING_PLAN_V2_URL = `/pricing/plans-v2`;

// Firm
export const FIRM_URL = `${CA_URL}/firm`;
export const FIRM_DETAILS_URL = `${FIRM_URL}/details`;
export const FIRM_CLIENT_CAPACITY_URL = `${FIRM_DETAILS_URL}/client-capacity`;
export const FIRM_AVAILABILITY_URL = `${FIRM_DETAILS_URL}/availability`;
export const FIRM_TEAM_URL = `${FIRM_URL}/team`;

export const statusToBadgeVariantMap = {
  [statusTypes.pending]: "ghost",
  [statusTypes.on_hold]: "warning_light",
  [statusTypes.upgrade_needed]: "warning_light",
  [statusTypes.in_progress]: "primary_light",
  [statusTypes.done]: "success_light",
};
