import { useDispatch } from "react-redux";
import {
  setPopupCenterComponentRender,
  setPopupCenterOpen,
  setPopupComponentRender,
  setPopupOpen,
} from "../Redux/popup/actions";

function usePopup() {
  const dispatch = useDispatch();

  const handlePopupOpen = (popupOpen: boolean) => {
    dispatch(setPopupOpen(popupOpen));
  };

  const handlePopupComponentRender = (popupComponent: JSX.Element) => {
    dispatch(setPopupComponentRender(popupComponent));
  };

  const handlePopupCenterOpen = (popupCenterOpen: boolean) => {
    dispatch(setPopupCenterOpen(popupCenterOpen));
  };

  const handlePopupCenterComponentRender = (
    popupCenterComponent: JSX.Element
  ) => {
    dispatch(setPopupCenterComponentRender(popupCenterComponent));
  };
  return {
    handlePopupOpen,
    handlePopupComponentRender,
    handlePopupCenterOpen,
    handlePopupCenterComponentRender,
  };
}

export default usePopup;
