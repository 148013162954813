import React from "react";
import styles from "./Loader.module.css";

const Loader = ({ loader }) => {
  return (
    <div
      className={loader === "main" ? styles.LoaderMain : styles.LoaderButton}
    ></div>
  );
};

export default Loader;
