import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  FETCH_USER_PROFILE_COMPLETION,
  FETCH_USER_PROFILE_COMPLETION_FAILURE,
  FETCH_USER_PROFILE_COMPLETION_SUCCESS,
  USER_CONTACT,
  USER_CONTACT_SUCCESS,
  USER_CONTACT_ERROR,
  VERIFY_USER_CONTACT,
  VERIFY_USER_CONTACT_SUCCESS,
  VERIFY_USER_CONTACT_ERROR,
} from "./types";

export const setFetchUser = () => {
  return {
    type: FETCH_USER,
  };
};
export const setFetchUserSuccess = (user) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: user,
  };
};
export const setFetchUserFailure = (error) => {
  return {
    type: FETCH_USER_FAILURE,
    payload: error,
  };
};
export const setUpdateUserSuccess = (user) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user,
  };
};
export const setFetchUserProfileCompletion = () => {
  return {
    type: FETCH_USER_PROFILE_COMPLETION,
  };
};
export const setFetchUserProfileCompletionSuccess = (user) => {
  return {
    type: FETCH_USER_PROFILE_COMPLETION_SUCCESS,
    payload: user,
  };
};
export const setFetchUserProfileCompletionFailure = (error) => {
  return {
    type: FETCH_USER_PROFILE_COMPLETION_FAILURE,
    payload: error,
  };
};
// user contact
export const setUserContact = () => {
  return {
    type: USER_CONTACT,
  };
};
export const setUserContactSuccess = () => {
  return {
    type: USER_CONTACT_SUCCESS,
  };
};
export const setUserContactError = (error) => {
  return {
    type: USER_CONTACT_ERROR,
    payload: error,
  };
};
export const setVerifyUserContact = () => {
  return {
    type: VERIFY_USER_CONTACT,
  };
};
export const setVerifyUserContactSuccess = () => {
  return {
    type: VERIFY_USER_CONTACT_SUCCESS,
  };
};
export const setVerifyUserContactError = (error) => {
  return {
    type: VERIFY_USER_CONTACT_ERROR,
    payload: error,
  };
};
