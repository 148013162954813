import { useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "../Services/auth/refreshToken";
import { axiosPrivate } from "../Services/axiosInit";
import { setHandleUserTokens } from "../Redux/auth/actions";
import useAuth from "./useAuth";
import signOut from "../Services/signOut";

function useAxiosPrivate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const { handleRefreshToken } = useAuth();

  useEffect(() => {
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        const isMissingFieldsError =
          error?.response?.data?.errors?.[0]?.name === "MissingFieldsError";
        const isInvalidFieldsError =
          error?.response?.data?.errors?.[0]?.name === "InvalidFieldsError";
        const isToken =
          error?.response?.data?.errors?.[0]?.missingFields?.[0] === "token";
        const isAccessToken =
          error?.response?.data?.errors?.[0]?.missingFields?.[0] ===
          "access token";
        const isTokenExpiredError =
          error?.response?.data?.errors?.[0].name === "TokenExpiredError";
        const isJwtExpired =
          error?.response?.data?.errors?.[0]?.message === "jwt expired";
        const isRefreshToken =
          error?.response?.data?.errors?.[0]?.missingFields?.[0] ===
          "refresh token";
        const isUserDoesNotExistError =
          error?.response?.data?.errors?.[0]?.name === "UserDoesNotExistError";
        if (
          (isMissingFieldsError && isToken) ||
          (isInvalidFieldsError && isAccessToken) ||
          (isJwtExpired && !prevRequest.sent)
        ) {
          prevRequest.sent = true;
          let refreshTokenData;
          try {
            const { data } = await refreshToken();
            refreshTokenData = data;
          } catch (error) {
            if (
              (isMissingFieldsError && isRefreshToken) ||
              (isJwtExpired && isTokenExpiredError) ||
              isUserDoesNotExistError
            ) {
              navigate(0);
              toast.dismiss();

              return await signOut();
            }
          }
          const newAccessToken = refreshTokenData.accessToken;
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          dispatch(setHandleUserTokens(newAccessToken));
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    };
  }, [handleRefreshToken, accessToken, dispatch]);

  return axiosPrivate;
}

export default useAxiosPrivate;
