import { GET_PRICING_PLAN_V2_URL } from '../Utils/constants';
import { AxiosInstance, AxiosResponse } from 'axios';

interface Params {
    financialYear: string;
    type: string;
}

type PricingPlan = Record<string, Record<string, any>[]>;

export const getPricingPlans2 = (
    axiosInstances: AxiosInstance,
    params: Params
): Promise<AxiosResponse<PricingPlan>> => {
    return axiosInstances.get(GET_PRICING_PLAN_V2_URL, { params });
};
