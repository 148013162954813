import React, { MouseEventHandler } from "react";
import Loader from "../Loader";
import styles from "./Button.module.css";

type IProps = {
  text?: string;
  icon?: React.ReactNode;
  src?: string;
  isActive?: boolean;
  onClick?: MouseEventHandler;
  className?: string;
  isReverse?: boolean;
  isDisabled?: boolean;
  disabledClassName?: string;
  defaultIcon?: string;
  imgClassName?: string;
  isLoading?: boolean;
  btnType?: string;
  id?: string;
}

const Button = ({
  text,
  src,
  icon,
  isActive,
  onClick,
  className,
  isReverse,
  isDisabled,
  disabledClassName,
  defaultIcon,
  imgClassName,
  isLoading = false,
  btnType,
  id,
}: IProps) => {
  return (
    <button
      id={id ?? ""}
      className={
        `${styles.Button} ${className} ${
          btnType === "primary" ? styles.PrimaryBtn : ""
        }` + (isDisabled ? ` ${disabledClassName}` : "")
      }
      onClick={onClick}
      style={
        isActive
          ? ({
              borderColor: "var(--primary-orange)",
              color: "var(--primary-orange)",
            } as React.CSSProperties)
          : undefined
      }
      disabled={isDisabled}
    >
      {isReverse
        ? null
        : isLoading
        ? null
        : text && <p className={styles.ButtonText}>{text}</p>}
      {src ? (
        <img
          src={src}
          alt="icon"
          className={`${styles.ButtonIcon} ${imgClassName}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultIcon as string;
          }}
        />
      ) : (
        icon
      )}

      {isReverse
        ? isLoading
          ? null
          : text && <p className={styles.ButtonText}>{text}</p>
        : null}
      {isLoading ? <Loader /> : ""}
    </button>
  );
};

export default Button;
