import staticData from "../staticData";
import styles from "./Footer.module.css";
import useMediaQuery from "../../Hooks/useMediaQuery";
const footerData = staticData.footer;

function Footer() {
  const [windowWidth] = useMediaQuery();
  return (
    <div className={styles.Wrapper}>
      <div className={styles.FooterWrapper}>
        <div className={styles.TopMostSection}>
          <div className="">
            <img src={footerData.topMostSection.logo} alt="KoinX Footer Logo" />
          </div>
          <div>
            <div className={styles.SocialMediaIcons}>
              {footerData.bottomSection.socialMedia.map((handle, index) => {
                if (windowWidth < 500) {
                  return (
                    <div className={styles.SocialMediaIconsMobile} key={index}>
                      <a
                        href={handle.url}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={handle.icon} alt={"social media icon"} />
                      </a>
                      <div className={styles.SocialMediaTextMobile}>
                        {handle.name}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <a
                      href={handle.url}
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.SocialMediaOnlyIcon}
                    >
                      <img src={handle.icon} alt={"social media icon"} />
                    </a>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className={styles.FooterMidSection}>
          {footerData.MidSection.map((item, index) => {
            return (
              <div key={index} className={styles.FooterMidSectionItem}>
                <div className={styles.FooterMidSectionItemHeading}>
                  {item.heading ? item.heading : null}
                </div>
                {item.links?.map((link, index) => {
                  if (
                    link.name === "Careers" &&
                    (windowWidth > 900 || windowWidth < 650)
                  ) {
                    return (
                      <div className={styles.CareersLink} key={index}>
                        <a
                          key={index}
                          target="_blank"
                          rel="noreferrer"
                          href={link.link}
                          className={styles.FooterMidSectionItemLink}
                        >
                          {link.name}
                        </a>
                        <div className={styles.HiringButton}>
                          {link.hireText}
                        </div>
                      </div>
                    );
                  } else if (
                    link.name === "Tax Professionals and Accountants"
                  ) {
                    return (
                      <a
                        key={index}
                        target="_blank"
                        rel="noreferrer"
                        href={link.link}
                        className={styles.FooterMidSectionItemLink2}
                      >
                        {link.name}
                      </a>
                    );
                  }
                  return (
                    <a
                      key={index}
                      target="_blank"
                      rel="noreferrer"
                      href={link.link}
                      className={styles.FooterMidSectionItemLink}
                    >
                      {link.name}
                    </a>
                  );
                })}
                {item.image ? (
                  <img className={styles.IsoImage} src={item.image} alt="iso" />
                ) : null}
              </div>
            );
          })}
        </div>
        <div className={styles.BottomFooterSection}>{footerData.copyright}</div>
      </div>
    </div>
  );
}

export default Footer;
