import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  FETCH_USER_PROFILE_COMPLETION,
  FETCH_USER_PROFILE_COMPLETION_SUCCESS,
  FETCH_USER_PROFILE_COMPLETION_FAILURE,
  USER_CONTACT,
  USER_CONTACT_SUCCESS,
  USER_CONTACT_ERROR,
  VERIFY_USER_CONTACT,
  VERIFY_USER_CONTACT_SUCCESS,
  VERIFY_USER_CONTACT_ERROR,
} from "./types";

const initialState = {
  userData: null,
  loading: false,
  userProfileCompletionLoading: false,
  userProfileCompletion: null,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_PROFILE_COMPLETION:
      return {
        ...state,
        userProfileCompletionLoading: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_USER_PROFILE_COMPLETION_FAILURE:
      return {
        ...state,
        userProfileCompletionLoading: false,
        error: action.payload,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
        loading: false,
      };
    case FETCH_USER_PROFILE_COMPLETION_SUCCESS:
      return {
        ...state,
        userProfileCompletionLoading: false,
        userProfileCompletion: action.payload,
      };
    case USER_CONTACT:
      return {
        ...state,
        userContactLoading: true,
      };
    case USER_CONTACT_SUCCESS:
      return {
        ...state,
        userContactLoading: false,
      };
    case USER_CONTACT_ERROR:
      return {
        ...state,
        userContactLoading: false,
        error: action.payload,
      };
    case VERIFY_USER_CONTACT:
      return {
        ...state,
        userContactLoading: true,
      };
    case VERIFY_USER_CONTACT_SUCCESS:
      return {
        ...state,
        userContactLoading: false,
      };
    case VERIFY_USER_CONTACT_ERROR:
      return {
        ...state,
        userContactLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export { userReducer };
