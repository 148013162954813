export enum AuthActionTypes {
  SIGNUP = "SIGNUP",
  SIGNUP_SUCCESS = "SIGNUP_SUCCESS",
  SIGNUP_ERROR = "SIGNUP_ERROR",

  SIGNOUT = "SIGNOUT",
  SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS",
  SIGNOUT_ERROR = "SIGNOUT_ERROR",

  VALIDATE_SIGNUP_OTP = "VALIDATE_SIGNUP_OTP",
  VALIDATE_SIGNUP_OTP_SUCCESS = "VALIDATE_SIGNUP_OTP_SUCCESS",
  VALIDATE_SIGNUP_OTP_ERROR = "VALIDATE_SIGNUP_OTP_ERROR",

  SIGNIN = "SIGNIN",
  SIGNIN_SUCCESS = "SIGNIN_SUCCESS",
  SIGNIN_ERROR = "SIGNIN_ERROR",

  VALIDATE_SIGNIN_OTP = "VALIDATE_SIGNIN_OTP",
  VALIDATE_SIGNIN_OTP_SUCCESS = "VALIDATE_SIGNIN_OTP_SUCCESS",
  VALIDATE_SIGNIN_OTP_ERROR = "VALIDATE_SIGNIN_OTP_ERROR",

  HANDLE_USER_TOKENS = "HANDLE_USER_TOKENS",
  HANDLE_USER_EMAIL_MOBILE = "HANDLE_USER_EMAIL_MOBILE",

  RESEND_OTP = "RESEND_OTP",
  RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS",
  RESEND_OTP_ERROR = "RESEND_OTP_ERROR",

  RESEND_SIGNIN_OTP = "RESEND_SIGNIN_OTP_SUCCESS",
  RESEND_SIGNIN_OTP_SUCCESS = "RESEND_SIGNIN_OTP_SUCCESS",
  RESEND_SIGNIN_OTP_ERROR = "RESEND_SIGNIN_OTP_ERROR",

  RESEND_SIGNUP_OTP = "RESEND_SIGNUP_OTP",

  GENERATE_CLIENT_AUTH_OTP = "GENERATE_CLIENT_AUTH_OTP",
  GENERATE_CLIENT_AUTH_OTP_SUCCESS = "GENERATE_CLIENT_AUTH_OTP_SUCCESS",
  GENERATE_CLIENT_AUTH_OTP_ERROR = "GENERATE_CLIENT_AUTH_OTP_ERROR",

  HANDLE_REFRESH_TOKEN = "HANDLE_REFRESH_TOKEN",
  HANDLE_REFRESH_TOKEN_SUCCESS = "HANDLE_REFRESH_TOKEN_SUCCESS",
  HANDLE_REFRESH_TOKEN_ERROR = "HANDLE_REFRESH_TOKEN_ERROR",

  GOOGLE_SIGN_IN = "GOOGLE_SIGN_IN",
  GOOGLE_SIGN_IN_SUCCESS = "GOOGLE_SIGN_IN_SUCCESS",
  GOOGLE_SIGN_IN_ERROR = "GOOGLE_SIGN_IN_ERROR",

  GOOGLE_SIGN_UP = "GOOGLE_SIGN_UP",
  GOOGLE_SIGN_UP_SUCCESS = "GOOGLE_SIGN_UP_SUCCESS",
  GOOGLE_SIGN_UP_ERROR = "GOOGLE_SIGN_UP_ERROR",

  BEGIN_AUTH = "BEGIN_AUTH",
  BEGIN_AUTH_SUCCESS = "BEGIN_AUTH_SUCCESS",
  BEGIN_AUTH_ERROR = "BEGIN_AUTH_ERROR",

  VALIDATE_OTP = "VALIDATE_OTP",
  VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS",
  VALIDATE_OTP_ERROR = "VALIDATE_OTP_ERROR",
}
