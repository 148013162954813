import { AxiosResponse } from "axios";
import { CaDetails, MessageResponse } from "../Interfaces/common";
import { SIGNUP_URL } from "../Utils/constants";
import axios from "./axiosInit";

export const signUp = (
  caDetails: CaDetails,

  recaptchaToken: string | null
): Promise<AxiosResponse<MessageResponse>> => {
  return axios.post(SIGNUP_URL, { ...caDetails, recaptchaToken });
};
