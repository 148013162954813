import { PopupActionTypes } from "./types";

const initialState = {
  popupOpen: false,
  popupComponent: null,
  popupCenterOpen: false,
  popupCenterComponent: null,
};

const popupReducer = (state: IPopupState = initialState, action: IAction) => {
  switch (action.type) {
    case PopupActionTypes.HANDLE_POPUP_OPEN: {
      return {
        ...state,
        popupOpen: action.popupOpen,
      };
    }
    case PopupActionTypes.HANDLE_POPUP_COMPONENT_RENDER: {
      return {
        ...state,
        popupComponent: action.popupComponent,
      };
    }
    // popup_center
    case PopupActionTypes.HANDLE_POPUP_CENTER_OPEN: {
      return {
        ...state,
        popupCenterOpen: action.popupCenterOpen,
      };
    }
    case PopupActionTypes.HANDLE_POPUP_CENTER_COMPONENT_RENDER: {
      return {
        ...state,
        popupCenterComponent: action.popupCenterComponent,
      };
    }
    default:
      return state;
  }
};

export { popupReducer };

export interface IPopupState {
  popupOpen: boolean;
  popupComponent: any;
  popupCenterOpen: boolean;
  popupCenterComponent: any;
}

type IAction =
  | PopupOpenAction
  | PopupComponentRenderAction
  | PopupCenterOpenAction
  | PopupCenterComponentRenderAction;

interface PopupOpenAction {
  type: PopupActionTypes.HANDLE_POPUP_OPEN;
  popupOpen: boolean;
}

interface PopupComponentRenderAction {
  type: PopupActionTypes.HANDLE_POPUP_COMPONENT_RENDER;
  popupComponent: JSX.Element;
}

interface PopupCenterOpenAction {
  type: PopupActionTypes.HANDLE_POPUP_CENTER_OPEN;
  popupCenterOpen: boolean;
}

interface PopupCenterComponentRenderAction {
  type: PopupActionTypes.HANDLE_POPUP_CENTER_COMPONENT_RENDER;
  popupCenterComponent: JSX.Element;
}
