import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { CaDetails } from "../Interfaces/common";
import {
  setBeginAuth,
  setBeginAuthError,
  setBeginAuthSuccess,
  setGenerateClientAuthOTP,
  setGenerateClientAuthOTPError,
  setGenerateClientAuthOTPSuccess,
  setGoogleSignin,
  setGoogleSigninError,
  setGoogleSigninSuccess,
  setHandleEmailMobile,
  setHandleRefreshToken,
  setHandleRefreshTokenError,
  setHandleRefreshTokenSuccess,
  setHandleUserTokens,
  setResendOtp,
  setResendOtpError,
  setResendOtpSuccess,
  setSignIn,
  setSignInError,
  setSignInSuccess,
  setSignOut,
  setSignOutError,
  setSignOutSuccess,
  setSignUp,
  setSignUpError,
  setSignUpSuccess,
  setValidateOTP,
  setValidateOTPError,
  setValidateOTPSuccess,
  setValidateSignInOtp,
  setValidateSignInOtpError,
  setValidateSignInOtpSuccess,
  setValidateSignupOtp,
  setValidateSignupOtpError,
  setValidateSignupOtpSuccess,
} from "../Redux/auth/actions";
import generateClientAuthOTP from "../Services/auth/generateClientAuthOTP";
import { refreshToken } from "../Services/auth/refreshToken";
import axiosInit, { axiosPrivate } from "../Services/axiosInit";
import signIn from "../Services/signIn";
import signOut from "../Services/signOut";
import { signUp } from "../Services/signUp";
import validateSignInOtp from "../Services/validateSignInOtp";
import validateSignUpOtp from "../Services/validateSignUpOtp";
import notify from "../Utils/notifyToast";
import { MAIN_APP_URL } from "../Utils/constants";
import { googleSignin } from "../Services/auth/googleSignin";
import { beginAuth } from "../Services/auth/beginAuth";
import { validateOTP } from "../Services/auth/validateOTP";
import { resendOTP } from "../Services/auth/resendOTP";

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignUp = async (
    caDetails: CaDetails,
    recaptchaToken: string | null,
    recaptchaRef: any
  ) => {
    dispatch(setSignUp());
    try {
      const response = await signUp(caDetails, recaptchaToken);
      dispatch(setHandleEmailMobile(caDetails.email, caDetails.mobile));
      dispatch(setSignUpSuccess(response.data.message));
      recaptchaRef.current?.reset();
      navigate(`/verifyotp`);
    } catch (error: any) {
      dispatch(setSignUpError(error));
      recaptchaRef.current?.reset();
      notify(
        error?.response?.data?.errors?.[0]?.message
          ? error?.response?.data?.errors?.[0]?.message
          : "Some server error occured, please try again later!",
        "error"
      );
    }
  };

  const handleValidateSignUpOTP = async (email: any, otp: string) => {
    try {
      dispatch(setValidateSignupOtp());
      const response = await validateSignUpOtp(email, otp);
      const {
        data: { message, accessToken },
      } = response;
      dispatch(setValidateSignupOtpSuccess(message));
      dispatch(setHandleUserTokens(accessToken));
      navigate("/");
    } catch (error: any) {
      dispatch(setValidateSignupOtpError(error));
      notify(
        error?.response?.data?.errors?.[0]?.message
          ? error?.response?.data?.errors?.[0]?.message
          : "Some server error occured, please try again later!",
        "error"
      );
    }
  };

  const handleSignIn = async (
    email: string,
    recaptchaToken: string | null,
    recaptchaRef: any
  ) => {
    try {
      dispatch(setSignIn());
      const response = await signIn(email, recaptchaToken);
      dispatch(setHandleEmailMobile(email));
      dispatch(setSignInSuccess(response.data.message));
      recaptchaRef.current?.reset();
      navigate(`/otp`);
    } catch (error: any) {
      dispatch(setSignInError(error));
      recaptchaRef.current?.reset();
      notify(
        error?.response?.data?.errors?.[0]?.message
          ? error?.response?.data?.errors?.[0]?.message
          : "Some server error occured, please try again later!",
        "error"
      );
    }
  };

  const handleValidateSignInOtp = async (
    email: string | undefined,
    otp: string
  ) => {
    try {
      dispatch(setValidateSignInOtp());
      const response = await validateSignInOtp(email, otp);
      const {
        data: { message, accessToken },
      } = response;
      dispatch(setValidateSignInOtpSuccess(message));
      dispatch(setHandleUserTokens(accessToken));
      navigate("/");
    } catch (error: any) {
      dispatch(setValidateSignInOtpError(error));
      notify(
        error?.response?.data?.errors?.[0]?.message
          ? error?.response?.data?.errors?.[0]?.message
          : "Some server error occured, please try again later!",
        "error"
      );
    }
  };

  const handleResendOTP = useCallback(
    (email: string | null, mobile: string | null, userId?: string) => {
      dispatch(setResendOtp());
      resendOTP(email, mobile, userId)
        .then((response: any) => {
          dispatch(setResendOtpSuccess(response.data.message));
          notify("OTP sent successfully", "success");
        })
        .catch((error: any) => {
          dispatch(
            setResendOtpError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch]
  );

  const handleSignout = async () => {
    try {
      dispatch(setSignOut());
      const response = await signOut(axiosInit);
      dispatch(setHandleUserTokens(null));
      dispatch(setSignOutSuccess(response.data.message));
      notify("Logout successfull", "success");
      navigate(0);
    } catch (error: any) {
      dispatch(setSignOutError(error));
      notify(
        error?.response?.data?.errors?.[0]?.message
          ? error?.response?.data?.errors?.[0]?.message
          : "Some server error occured, please try again later!",
        "error"
      );
    }
  };

  const handleGenerateClientAuthOTP = useCallback(
    (clientId: string, redirect: string = "dashboard") => {
      dispatch(setGenerateClientAuthOTP());

      generateClientAuthOTP(axiosPrivate, clientId)
        .then((response) => {
          dispatch(setGenerateClientAuthOTPSuccess(response.data));
          window.open(
            `${MAIN_APP_URL}/?clientOTP=${response.data.otp}&redirect=${redirect}`,
            "_blank"
          );
        })
        .catch((error) => {
          dispatch(
            setGenerateClientAuthOTPError(
              error?.response?.data?.errors[0].message
                ? error?.response?.data?.errors[0].message
                : "Some server error occured, please try again later!"
            )
          );
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch]
  );

  const handleRefreshToken = useCallback(
    (setIsAppInitalized: any) => {
      dispatch(setHandleRefreshToken());

      refreshToken()
        .then((response) => {
          dispatch(setHandleRefreshTokenSuccess(response.data.accessToken));
          dispatch(setHandleUserTokens(response.data.accessToken));
        })
        .catch((error) => {
          setIsAppInitalized(true);
          dispatch(
            setHandleRefreshTokenError(
              error?.response?.data?.errors[0]
                ? error.response.data.errors[0]
                : "Some server error occured, please try again later!"
            )
          );
          // navigate("/get-started");
        });
    },
    [dispatch, navigate]
  );

  const handleGoogleSignin = useCallback(
    (token: string, referrerCode: string | null) => {
      dispatch(setGoogleSignin());
      googleSignin(token, referrerCode)
        .then((response) => {
          dispatch(setGoogleSigninSuccess(response.data.message));
          dispatch(setHandleUserTokens(response.data.accessToken));
          sessionStorage.setItem("isNewUser", response.data.newUser);
          if (response.data.newUser) {
            localStorage.setItem("isEligibleCSKuber", response.data.newUser);
          } else {
          }
          notify("Sign up successfull", "success");
        })
        .catch((error) => {
          dispatch(
            setGoogleSigninError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch, navigate]
  );

  const handleBeginAuth = useCallback(
    (
      email: string | null,
      mobile: any,
      recaptchaToken: string | null,
      resetCaptcha: any
    ) => {
      dispatch(setBeginAuth());
      // gaEventTracker("Authentication Begin", "Authentication Begin");
      beginAuth({ email, mobile, recaptchaToken })
        .then((response) => {
          const contact = email ? email : mobile;
          dispatch(setBeginAuthSuccess(response.data.message, contact));
          resetCaptcha();
          response.data.newUser
            ? navigate(`/otp?flow=signup`)
            : navigate(`/otp?flow=signin`);
          sessionStorage.setItem("isNewUser", response.data.newUser);
          notify("OTP sent successfully", "success");
        })
        .catch((error) => {
          dispatch(
            setBeginAuthError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          resetCaptcha();
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch, navigate]
  );

  const handleValidateOTP = useCallback(
    (otp: any, email: any, mobile: any, referralCode: any) => {
      dispatch(setValidateOTP());
      const inviteId = sessionStorage.getItem("inviteId");
      validateOTP(otp, email, mobile, referralCode, inviteId)
        .then((response: any) => {
          dispatch(
            setValidateOTPSuccess(response.data.message, response.data.newUser)
          );
          dispatch(setHandleUserTokens(response.data.accessToken));

          navigate("/");
          localStorage.setItem("isNewUser", response.data.newUser);
          sessionStorage.setItem("isNewUser", response.data.newUser);
          notify("Onboarding successfull", "success");
          sessionStorage.removeItem("inviteId");
        })
        .catch((error: any) => {
          dispatch(
            setValidateOTPError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          // This fix is for the case when the user enters the wrong OTP and then enters the correct OTP
          sessionStorage.removeItem("inviteId");
          // For more details refer to the backend code
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch, navigate]
  );

  return {
    handleSignUp,
    handleValidateSignUpOTP,
    handleSignIn,
    handleValidateSignInOtp,
    handleResendOTP,
    handleSignout,
    handleGenerateClientAuthOTP,
    handleRefreshToken,
    handleGoogleSignin,
    handleBeginAuth,
    handleValidateOTP,
  };
};

export default useAuth;
